const constants = {
  getCurrentSession: 'getCurrentSession',
  appStoreUrl: 'https://apps.apple.com/ar/app/headlights-hip-hop/id1526222689?l=en',
  cityAcronyms: {
    chicago: 'CHI',
    detroit: 'DET',
    losAngeles: 'LA',
    atlanta: 'ATL',
    miami: 'MIA',
    newYork: 'NYC',
  },
};

export default constants;
