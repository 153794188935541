import { createSlice } from '@reduxjs/toolkit';
import { getLiveSession, getCitySessionById, castEventVote } from '../../api/liveSession.api';
import { userVotedOnEvent } from '../auth/authSlice';
import { trackUserVote } from '../../api/analytics';

const initialState = {
  liveSession: null,
  loading: true,
  error: null,
  videoMute: true,
  currentArtistOnSlide: null,
  sliderMoving: false,
  currentCityInSliderIndex: 0,
  citiesCurrentArtistIndexes: {},
  swipeCityLoading: false
};

function startLoading(state) {
  state.loading = true;
}

function startSwipeCityLoading(state) {
  state.swipeCityLoading = true;
}

function loadingFailed(state, action) {
  state.loading = false;
  state.error = action.payload;
}

function onSliderMovingChange(state, { payload }) {
  state.sliderMoving = payload;
}

function onCurrenArtistOnSlideChange(state, { payload }) {
  state.currentArtistOnSlide = payload;
}

function onCurrentCityOnSliderChange(state, { payload }) {
  state.currentCityInSliderIndex = payload;
}

export const liveSessionSlice = createSlice({
  name: 'liveSession',
  initialState,
  reducers: {
    liveSessionLoading: startLoading,
    liveSessionSuccess: (state, { payload }) => {
      const { liveSession } = payload;

      state.liveSession = { ...liveSession };
      state.citiesCurrentArtistIndexes = liveSession.citySessions
        .map((c) => c.id)
        .reduce(
          (obj, cityId) => ({
            ...obj,
            [cityId]: { selected: false, index: 0, showingTop3: false },
          }),
          {},
        );
      state.loading = false;
      state.error = null;
    },

    citySessionSuccess: (state, { payload }) => {
      const { citySession } = payload;

      const currentLiveSession = { ...state.liveSession };
      currentLiveSession.citySessions.find((cs) => cs.id === citySession.id).contestants =
        citySession.contestants;
      state.liveSession = currentLiveSession;

      state.loading = false;
      state.error = null;
    },

    swipeCityLoading: startSwipeCityLoading,

    swipeCitySuccess: (state, { payload }) => {
      const { citySession } = payload;

      const currentLiveSession = { ...state.liveSession };
      currentLiveSession.citySessions.find((cs) => cs.id === citySession.id).contestants =
        citySession.contestants;
      state.liveSession = currentLiveSession;

      state.swipeCityLoading = false;
      state.loading = false;
      state.error = null;
    },

    videoMuteToggle: (state) => {
      state.videoMute = !state.videoMute;
    },
    liveSessionFailure: loadingFailed,
    currenArtistOnSlideChange: onCurrenArtistOnSlideChange,
    sliderMovingChange: onSliderMovingChange,
    currentCityOnSliderChange: onCurrentCityOnSliderChange,
    cityArtistSlideIndexUpdated: (state, { payload }) => {
      const { cityId, index } = payload;
      const citiesCurrentArtistIndexes = { ...state.citiesCurrentArtistIndexes }; //, [cityId]:{selected: true, index: index }}
      //update the selected location and index
      for (const [key] of Object.entries(citiesCurrentArtistIndexes)) {
        if (key === cityId) {
          citiesCurrentArtistIndexes[key] = { ...citiesCurrentArtistIndexes[key], index };
        }
      }

      state.citiesCurrentArtistIndexes = citiesCurrentArtistIndexes;
    },
    cityTop3DisplayStatusChanged: (state, { payload }) => {
      const { cityId, showing } = payload;
      const citiesCurrentArtistIndexes = { ...state.citiesCurrentArtistIndexes }; //, [cityId]:{selected: true, index: index }}
      //update the selected location and index
      for (const [key] of Object.entries(citiesCurrentArtistIndexes)) {
        if (key === cityId) {
          citiesCurrentArtistIndexes[key] = {
            ...citiesCurrentArtistIndexes[key],
            showingTop3: showing,
          };
        }
      }

      state.citiesCurrentArtistIndexes = citiesCurrentArtistIndexes;
    },
  },
});

export const {
  liveSessionLoading,
  liveSessionSuccess,
  liveSessionFailure,
  videoMuteToggle,
  sliderMovingChange,
  currenArtistOnSlideChange,
  currentCityOnSliderChange,
  cityArtistSlideIndexUpdated,
  cityTop3DisplayStatusChanged,
  citySessionSuccess,
  swipeCityLoading,
  swipeCitySuccess
} = liveSessionSlice.actions;

export default liveSessionSlice.reducer;

export const selectSession = (state) => state.liveSession.liveSession;

export const selectCitySessions = (state) =>
  state.liveSession.liveSession && state.liveSession.liveSession.citySessions;

export const selectCitiesCurrentArtistIndexes = (state) => {
  return state.liveSession && state.liveSession && state.liveSession.citiesCurrentArtistIndexes;
};

export const selectSessionVendors = (state) => {
  return state.liveSession.liveSession && state.liveSession.liveSession.vendors;
};

export const selectArtistVotesOnSlid = (state) => {
  return (
    state.liveSession &&
    state.liveSession.currentArtistOnSlide &&
    state.liveSession.currentArtistOnSlide.votes
  );
};

export const selectTop3StatusForCurrentCity = (state) => {
  if (
    state.liveSession &&
    state.liveSession.liveSession &&
    state.liveSession.citiesCurrentArtistIndexes
  ) {
    if (
      state.liveSession.currentCityInSliderIndex < state.liveSession.liveSession.citySessions.length
    ) {
      const currentCitySession =
        state.liveSession.liveSession.citySessions[state.liveSession.currentCityInSliderIndex];
      return state.liveSession.citiesCurrentArtistIndexes[currentCitySession?.id || 'atlanta'];
    }
  }
};

export const selectCurrentCitySessionIndex = (state) =>
  state.liveSession && state.liveSession.currentCityInSliderIndex;

export const selectCurrentCitySession = (state) => {
  return (
    state &&
    state.liveSession &&
    state.liveSession.liveSession &&
    state.liveSession.liveSession.citySessions &&
    state.liveSession.liveSession.citySessions.length > 0 &&
    state.liveSession.liveSession.citySessions[state.liveSession.currentCityInSliderIndex]
  );
};

export const selectCurrentArtistOnSlide = (state) =>
  state.liveSession && state.liveSession.currentArtistOnSlide;

export const selectSliderMoving = (state) => state.liveSession && state.liveSession.sliderMoving;

export const selectVideoMute = (state) => state.liveSession.videoMute;

export const selectLiveSessionLoading = (state) => state.liveSession.loading;

export const selectCitySwipeLoading = (state) => state.liveSession.swipeCityLoading;

export const selectLiveSessionRealId = (state) =>
  state.liveSession.liveSession && state.liveSession.liveSession.id;

export const selectCurrentTopArtists = (state) => {
  if (state.liveSession.liveSession) {
    const citySessions = state.liveSession.liveSession.citySessions;
    let topArtistsBySession = {};
    for (const citySession of citySessions) {
      if (citySession.contestants && citySession.contestants.length > 0) {
        const sortedContestantsByVotes = citySession.contestants
          .slice()
          .sort((a, b) => b.votes - a.votes);
        const { id } = citySession;
        const citySessionTop3 = {
          top1: sortedContestantsByVotes[0],
          top2: sortedContestantsByVotes[1],
          top3: sortedContestantsByVotes[2],
        };

        topArtistsBySession[id] = citySessionTop3;
      }
    }

    return topArtistsBySession;
  }
};

//Thunk
export const getCurrentLiveSession = (cityId) => async (dispatch) => {
  try {
    dispatch(liveSessionLoading());

    const liveSession = await getLiveSession();
    if (!cityId) {
      cityId = 'detroit'
    }
    const citySession = await getCitySessionById(cityId)

    const citiesSessionsIds = liveSession.citySessions.map((city) => {
      delete city.contestants
      return city
    });

    const currentCityIndex = citiesSessionsIds.findIndex((c) => c.id === cityId);
    citiesSessionsIds[currentCityIndex] = citySession
    dispatch(currentCityOnSliderChange(currentCityIndex));

    dispatch(
      liveSessionSuccess({ liveSession: { ...liveSession, citySessions: citiesSessionsIds } }),
    );
  } catch (err) {
    dispatch(liveSessionFailure(err.toString()));
  }
};

export const getCitySession =
  ({ cityId }) =>
  async (dispatch) => {
    try {
      dispatch(liveSessionLoading());

      const citySession = await getCitySessionById(cityId);
      dispatch(citySessionSuccess({ citySession: citySession }));
    } catch (err) {
      dispatch(liveSessionFailure(err.toString()));
    }
  };

  export const getCitySessionAtSwipe =
  ({ cityId }) =>
  async (dispatch) => {
    try {
      dispatch(swipeCityLoading());
      const citySession = await getCitySessionById(cityId);
      dispatch(swipeCitySuccess({ citySession: citySession }));
    } catch (err) {
      dispatch(liveSessionFailure(err.toString()));
    }
  };


export const castLiveSessionVote =
  ({ competitionId, cityId, contestantId, userId }) =>
  async (dispatch) => {
    try {
      dispatch(userVotedOnEvent({ cityId }));
      await castEventVote(competitionId, cityId, contestantId, userId);
      trackUserVote(userId, contestantId, competitionId, cityId);
    } catch (err) {
      console.error(err);
    }
  };
