import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenModal: false,
};

export const endOfStreamModal = createSlice({
  name: 'endOfStream',
  initialState,
  reducers: {
    setModalVisibility: (state, { payload }) => {
      const { isOpenModal } = payload;
      state.isOpenModal = isOpenModal;
    },
  },
});

export const { setModalVisibility } = endOfStreamModal.actions;

export default endOfStreamModal.reducer;

export const getModalVisibility = (state) => state.endOfStreamModal.isOpenModal;
