import React, { useEffect } from 'react';
import './product.css';
import SingleProduct from './SingleProduct';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectCurrentUser } from '../auth//authSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './productList.css';
import {
  selectUserPurchases,
  loadUserPurchases,
  selectPurchasesLoading,
} from './userPurchasesSlice';
import { loadProduct, selectProduct, selectProductLoading } from './singleProductSlice';


export default function SingleProductShow() {
  const dispatch = useDispatch();
  const { productId } = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const customerId = (currentUser && currentUser.id);
  const product = useSelector(selectProduct);
  const isLoading = useSelector(selectProductLoading);
  const userPurchases = useSelector(selectUserPurchases);
  const userPurchasesLoading = useSelector(selectPurchasesLoading);
  const purchaseIndex = userPurchases.findIndex(p => p.productId === productId)

  useEffect(() => {
    dispatch(loadUserPurchases(customerId));
  }, [customerId]);

  useEffect(() => {
    dispatch(loadProduct(productId));
  }, []);


  
  if ( userPurchasesLoading || isLoading) {
    return (
      <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  if (purchaseIndex !== -1 && product.productType !== 'votesPurchase') {
    return <SingleProduct disabledMessage='already purchased' key={product.id} {...product} customerId={customerId}></SingleProduct> ;
  }

  if (product.stock <= 0) {
    return <SingleProduct disabledMessage='Product out of stock' key={product.id} {...product} customerId={customerId}></SingleProduct> ;
  }

  return  <SingleProduct key={product.id} {...product} customerId={customerId}></SingleProduct>

}
