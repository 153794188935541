import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ProfileModal } from '../../artistProfile/ProfileContainer';

const useStyles = makeStyles(() => ({
  dialImage: {
    height: '53px',
    width: '50px',
  },
  dialImageCircle: {
    width: '50px',
    height: '53px',
    borderRadius: '50%',
  },
  cellContainer: {
    margin: 'auto',
    height: '80%',
    display: 'grid',
    gridRowGap: '4pt',
  },
  artistName: {
    color: 'white',
    wordBreak: 'break-word',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '11pt',
  },
  profilePictureContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
}));

export const ArtistCell = ({ artist }) => {
  const [artistProfileModalOpen, setArtistProfileModalOpen] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.cellContainer}>
      <div
        className={classes.profilePictureContainer}
        onClick={() => setArtistProfileModalOpen(true)}
      >
        <div className={classes.dialImageCircle}>
          <img className={classes.dialImage} src={artist.profileAssets.dialUrl} alt="" />
        </div>
      </div>

      <label className={classes.artistName}> {artist.artistName}</label>
      <ProfileModal
        fromliveSession={false}
        open={artistProfileModalOpen}
        onClose={() => setArtistProfileModalOpen(false)}
        artistId={artist.id}
        isCurrentUser={false}
        position={artist.position}
      />
    </div>
  );
};
