import firebase from 'firebase/app';
import 'firebase/auth';
import { getRequest, postRequest } from './authHttpClient';
import { config } from '../utils/apiConfig';
const resourceUrl = `${config.API_URL}/profile/`;

export const getCurrentUserProfile = async () => {
  try {
    const currentUserId = firebase.auth().currentUser.uid;
    const result = await getRequest(resourceUrl, {
      userId: currentUserId,
    });
    if (result.status !== 200) {
      return null;
    }

    if (!result.data.id) result.data.id = currentUserId;

    return result.data;
  } catch (err) {
    console.error('err:', err);
    if (err.response.code === 404 || err.message.includes('404')) {
      return;
    }
    //todo: add analytics for errors
    return null;
  }
};

export const createNewUserProfile = async (newUserProfile) => {
  const currentUserId = firebase.auth().currentUser.uid;
  return await postRequest(resourceUrl, newUserProfile, {
    userId: currentUserId,
  });
};
