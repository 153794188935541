import { loadStripe } from '@stripe/stripe-js';
import config from '../envConfig';
import { config as apiConfig } from '../utils/apiConfig';
import { postRequest } from './authHttpClient';
import { getRequest } from './authHttpClient';

export const stripePromise = loadStripe(config.stripePublishableKey);

export const createStripePaymentIntent = async (userId, productId, platform) => {
  const resourceURL = `${apiConfig.API_URL}/ecommercePurchase/paymentIntent`;
  const response = await postRequest(resourceURL, {
    userId,
    productId,
    platform,
  });
  const { clientSecret } = response?.data;
  return clientSecret;
};


export const createCheckoutSession = async (userId, priceId, metadata) => {
  const resourceURL = `${apiConfig.API_URL}/ecommercePurchase/create-checkout-session`;
  const response = await postRequest(resourceURL, {
    userId,
    priceId,
    metadata
  });
  const { checkoutSessionUrl } = response?.data;
  return checkoutSessionUrl;
};


export const getProducts = async () => {
  const resourceURL = `${apiConfig.API_URL}/ecommerceProducts`;
  const result = await getRequest(resourceURL);
  return result.data;
};

export const getUserPurchases = async (userId) => {
  const resourceURL = `${apiConfig.API_URL}/ecommercePurchase/${userId}`;
  const result = await getRequest(resourceURL);
  return result.data;
};

export const getProduct = async (productId) => {
  const resourceURL = `${apiConfig.API_URL}/ecommerceProducts/${productId}`;
  const result = await getRequest(resourceURL);
  return result.data;
};

export const getProductByIntent = async (intentId) => {
  const resourceURL = `${apiConfig.API_URL}/ecommerceProducts/intent/${intentId}`;
  const result = await getRequest(resourceURL);
  return result.data;
};