import '../../utils/slick.css';
import '../../utils/slickTheme.css';
import './LiveSession.css';
import { CityHorizontalNavigation } from './CityHorizontalNavigation';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCitySession,
  getCurrentLiveSession,
  selectCitySessions,
  selectCurrentCitySessionIndex,
  selectLiveSessionLoading,
  selectCitySwipeLoading,
  getCitySessionAtSwipe,
} from './liveSessionSlice';
import { CircularProgress } from '@material-ui/core';
import styles from './LiveSession.module.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useValidateCity from '../../utils/hooks/UseValidateCity';
import useReplaceLink from '../../utils/hooks/UseReplaceLink';
import { SwiperSlide } from 'swiper/react';
import CitySessionSlider from './components/CitySessionSlider';
import { useEffect } from 'react';
import { VideoContainer } from './components/VideoContainer';
import { selectSessionVendors } from './liveSessionSlice';

export function LiveSession() {
  const dispatch = useDispatch();

  const isLoading = useSelector(selectLiveSessionLoading);
  const citySessions = useSelector(selectCitySessions);
  const citySessionIndex = useSelector(selectCurrentCitySessionIndex);
  const vendors = useSelector(selectSessionVendors) || [];
  let { cityId } = useParams();
  const isCitySwipeLoading = useSelector(selectCitySwipeLoading)
  useValidateCity(cityId);
  useReplaceLink(isLoading);

  useEffect(() => {
    if (!citySessions) {
      dispatch(getCurrentLiveSession(cityId));
    }
  }, [citySessions, dispatch, citySessionIndex]);

  useEffect(() => {
    if (citySessions && !citySessions[citySessionIndex].contestants && !citySessions[citySessionIndex].isComingSoon) {
      dispatch(getCitySessionAtSwipe({cityId: citySessions[citySessionIndex].id}));
    }
  }, [citySessionIndex]);

  return (
    <>
      {isLoading ? (
        <div className={styles.loading}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          <CityHorizontalNavigation>
            {(citySessions || []).map((session, index) => (
              <SwiperSlide key={session.id} virtualIndex={index}>
                  {isCitySwipeLoading && !session.contestants ? (
                <div style={{position: 'absolute'}} key={index} className={styles.loading}>
                  <CircularProgress color="primary" />
                </div>
              ) :
                <CitySessionSlider
                  contestants={session.contestants?.concat(vendors) || []}
                  acronym={session.acronym}
                  currentCitySessionIndex={citySessionIndex}
                  index={index}
                  citySessionId={session.id}
                  isComingSoon={session.isComingSoon}
                  sessionStatus={session.status}
                />
                }
              </SwiperSlide>
            ))}
          </CityHorizontalNavigation>
          {!citySessions?.[citySessionIndex]?.isComingSoon && (
            <VideoContainer className={`${styles.videoContainer}  videoContainer`} />
          )}
        </>
      )}
    </>
  );
}
