import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cities: [
    { id: 'chicago', acronym: 'CHI', isComingSoon: false },
    { id: 'detroit', acronym: 'DET', isComingSoon: false },
    { id: 'losAngeles', acronym: 'LAX', isComingSoon: true },
    { id: 'atlanta', acronym: 'ATL', isComingSoon: true },
    { id: 'miami', acronym: 'MIA', isComingSoon: true },
    { id: 'newYork', acronym: 'NYC', isComingSoon: true },
  ],
  currentCityId: null,
  currentCityIndex: null,
};

export const cityNavigationSlice = createSlice({
  name: 'cityNavigation',
  initialState,
  reducers: {
    cityNavigationChanged: (state, { payload }) => {
      const { newCityIndex } = payload;
      const nextCity = state.cities[newCityIndex];
      state.currentCityId = nextCity.id;
      state.currentCityIndex = newCityIndex;
    },
    loadCityNavigation: (state, { payload }) => {
      const { cities } = payload;

      const defaultCity = cities[0];

      state.cities = cities;
      state.currentCityId = defaultCity.id;
      state.currentCityIndex = 0;
    },
  },
});

export const selectCities = (state) => state.cityNavigation && state.cityNavigation.cities;
export const selectCurrentCityId = (state) => state.cityNavigation.currentCityId;
export const selectcurrentCityIndex = (state) => state.cityNavigation.currentCityIndex;

export const { cityNavigationChanged, loadCityNavigation } = cityNavigationSlice.actions;

export default cityNavigationSlice.reducer;
