import React from 'react';
import { selectExampleModalTapped, exampleModalClosed } from './artistRequestSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import styles from './ArtistRequestPictureExample.module.css';
import { Modal } from '@material-ui/core';

export const ArtistRequestPictureExample = () => {
  const dispatch = useDispatch();
  const exampleTapped = useSelector(selectExampleModalTapped);
  const closeModal = () => dispatch(exampleModalClosed());

  return exampleTapped ? (
    <Modal open={exampleTapped !== undefined}>
      <div className={styles.container}>
        <Typography variant="h5" gutterBottom className={styles.exampleTitle}>
          Example
        </Typography>
        <img src="/assets/example-artist.png" className={styles.exampleImage} alt="" />
        <button className={styles.gotItButton} onClick={closeModal}>
          Got it
        </button>
      </div>
    </Modal>
  ) : null;
};
