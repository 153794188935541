import React from 'react';
import '../../../utils/slick.css';
import '../../../utils/slickTheme.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SwiperCore, { Mousewheel } from 'swiper';
import { useDispatch } from 'react-redux';
import { currenArtistOnSlideChange } from '../liveSessionSlice';
import './citySession.css';
import '../LiveSession.css';
import { PendingToStartGrid } from './PendingToStartGrid';
import { VideoScreen } from './VideoScreen';

const useStyles = makeStyles(() => ({
  comingSoonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    color: 'white',
    height: '100vh',
  },
  comingSoonInfo: {
    padding: '0 30px',
  },
  bigTitle: {
    fontWeight: 'bold',
  },
}));

const ComingSoonScreen = ({ cityName, citySessionIndex, currentCitySessionIndex }) => {
  const dispatch = useDispatch();

  if (citySessionIndex === currentCitySessionIndex) {
    dispatch(currenArtistOnSlideChange(null));
  }

  const classes = useStyles();
  return (
    <div className={`${classes.comingSoonContainer} comingSoonContainer`}>
      <div className={classes.comingSoonInfo}>
        <Typography variant="h6" gutterBottom className={classes.bigTitle}>
          Heads Up.
        </Typography>
        <Typography variant="h4" gutterBottom className={classes.bigTitle}>
          {`${cityName || ''} Coming Soon`}
        </Typography>
      </div>
    </div>
  );
};

SwiperCore.use([Mousewheel]);

function CitySessionSlider({
  contestants,
  cityName,
  citySessionIndex,
  currentCitySessionIndex,
  citySessionId,
  isComingSoon,
  sessionStatus,
}) {
  return !isComingSoon ? (
    sessionStatus !== 'pendingToStart' ? (
      <div>
        <VideoScreen
          contestants={contestants}
          citySessionIndex={citySessionIndex}
          currentCitySessionIndex={currentCitySessionIndex}
          citySessionId={citySessionId}
        />
      </div>
    ) : (
      <PendingToStartGrid citySessionId={citySessionId} contestants={contestants} />
    )
  ) : (
    <ComingSoonScreen
      cityName={cityName}
      citySessionIndex={citySessionIndex}
      currentCitySessionIndex={currentCitySessionIndex}
    />
  );
}

export default CitySessionSlider;
