import { createSlice } from '@reduxjs/toolkit';
import { getLeaderBoard, castVote } from '../../api/leaderBoard.api';

const initialState = {
  leaderBoard: null,
  loading: false,
  error: null,
  cityCardIndexes: {},
};

function startLoading(state) {
  state.loading = true;
}

function loadingFailed(state, action) {
  state.loading = false;
  state.error = action.payload;
}

export const leaderBoardSlice = createSlice({
  name: 'leaderBoard',
  initialState,
  reducers: {
    leaderBoardLoading: startLoading,
    leaderBoardSuccess: (state, { payload }) => {
      const { leaderBoard } = payload;
      leaderBoard.cityLeaderboards.sort(
        (cityA, cityB) => (cityB.artists || []).length - (cityA.artists || []).length,
      );

      state.leaderBoard = { ...leaderBoard };
      state.cityCardIndexes = leaderBoard.cityLeaderboards
        .map((c) => c.cityId)
        .reduce((obj, cityId) => ({ ...obj, [cityId]: { selected: false, cardIndex: 0 } }), {});
      state.loading = false;
      state.error = null;
    },

    eventArtistVoted: (state, { payload }) => {
      const { cityId, contestantId } = payload;
      const leaderBoard = { ...state.leaderBoard };
      leaderBoard.cityLeaderboards
        .find((l) => (l.cityId = cityId))
        .artists.find((a) => (a.id = contestantId)).totalHistoricalVotes++;
      state.leaderBoard = { ...leaderBoard };
      state.loading = false;
      state.error = null;
    },
    leaderBoardFailure: loadingFailed,
    cityCardIndexUpdated: (state, { payload }) => {
      const { cityId, index } = payload;
      const cityCardIndexes = { ...state.cityCardIndexes }; //, [cityId]:{selected: true, index: index }}
      //update the selected location and index
      for (const [key] of Object.entries(cityCardIndexes)) {
        if (key === cityId) {
          cityCardIndexes[key] = { ...cityCardIndexes[key], index };
        }
      }

      state.cityCardIndexes = cityCardIndexes;
    },
    cityIndexUpdated: (state, { payload }) => {
      const { cityId } = payload;
      const cityCardIndexes = { ...state.cityCardIndexes }; //, [cityId]:{selected: true, index: index }}
      //update the selected location and index
      for (const [key] of Object.entries(cityCardIndexes)) {
        if (key === cityId) {
          cityCardIndexes[key] = { ...cityCardIndexes[key], selected: true };
        } else {
          cityCardIndexes[key] = { ...cityCardIndexes[key], selected: false };
        }
      }

      state.cityCardIndexes = cityCardIndexes;
    },
  },
});

export const {
  leaderBoardLoading,
  leaderBoardSuccess,
  leaderBoardFailure,
  cityCardIndexUpdated,
  cityIndexUpdated,
  eventArtistVoted,
} = leaderBoardSlice.actions;

export default leaderBoardSlice.reducer;

export const selectLeaderBoard = (state) =>
  state.leaderBoard &&
  state.leaderBoard.leaderBoard &&
  state.leaderBoard.leaderBoard.cityLeaderboards;

export const selectLeaderBoardLoading = (state) => state.leaderBoard.loading;

export const selectCityCardIndexes = (state) => state.leaderBoard.cityCardIndexes;

export const selectCityIndex = (state) => {
  if (state.leaderBoard && state.leaderBoard.cityCardIndexes) {
    const selectedCityIndex = Object.entries(state.leaderBoard.cityCardIndexes).findIndex(
      // eslint-disable-next-line no-unused-vars
      ([cityId, value]) => value.selected,
    );
    return selectedCityIndex === -1 ? 0 : selectedCityIndex;
  }
};

//Thunk
export const getCurrentLeaderBoard = () => async (dispatch) => {
  try {
    dispatch(leaderBoardLoading());
    const generalLeaderboard = await getLeaderBoard();
    dispatch(leaderBoardSuccess({ leaderBoard: generalLeaderboard }));
  } catch (err) {
    dispatch(leaderBoardFailure(err.toString()));
  }
};
