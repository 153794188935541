import React from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styles from './TopNavigationBar.module.css';
import { useHistory } from 'react-router-dom';

export const TopNavigationBar = ({ navBarTitle, rightButtonLabel, onRightButtonTapped }) => {
  const history = useHistory();

  return (
    <Toolbar variant="dense" className={styles.container}>
      <IconButton
        onClick={() => history.goBack()}
        color="primary"
        aria-label="go back"
        component="span"
      >
        <ArrowBackIosIcon />
      </IconButton>
      <Typography variant="h6" className={styles.typographyTitle}>
        {navBarTitle}
      </Typography>
      <Button onClick={onRightButtonTapped} color="primary" aria-label="go back">
        {rightButtonLabel}
      </Button>
    </Toolbar>
  );
};
