import React from 'react';
import './product.css';
import { useHistory, useLocation } from 'react-router-dom';
import { trackUserViewItem } from '../../api/analytics';
import { useEffect } from 'react';
import { createStripeCheckoutSession, selectIsCheckoutSessionLoading, selectCheckoutSession, selectPlatform } from './ecommerceSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function Product({
  name,
  price,
  id,
  descriptionItems,
  buttonText,
  timeRange,
  customerId,
  disabledMessage,
  productType,
  priceId
}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const platform = useSelector(selectPlatform)
  const checkoutSessionLink = useSelector(selectCheckoutSession)

  //execute trackUserViewItem when the component mounts
  useEffect(() => {
    trackUserViewItem(id, name);
  }, []);

  useEffect(() => {
    if (checkoutSessionLink) {
      window.location.href = checkoutSessionLink;
    }
  }, [checkoutSessionLink]); 

  const handlePurchase = () => {
    if (productType === 'monthlySubscription') {
      const metadata = {
        productId: id,
        customerId,
        platform,
        price
      }
      dispatch(createStripeCheckoutSession({ userId:customerId, priceId, metadata }))

    } else {
      const actualRoute = location.pathname;
      const newRoute = `${actualRoute}${actualRoute.endsWith('/') ? '' : '/'}${customerId}/${id}`;
      history.push(newRoute);
    }

  };

  return (
    <div className="product">
      <h2>{name}</h2>
      <h2>
        ${price}
        {timeRange ? `/${timeRange}` : ''}
      </h2>
      {descriptionItems && descriptionItems.map((item) => <p key={item}>- {item}</p>)}
      {disabledMessage ? (
        <button style={{ backgroundColor: 'grey' }} disabled onClick={handlePurchase}>
          {disabledMessage}
        </button>
      ) : (
        <button onClick={handlePurchase}>{buttonText || 'Purchase'}</button>
      )}
    </div>
  );
}
