import React, { useState } from 'react';
import styles from '../LiveSession.module.css';
import { ProfileModal } from '../../artistProfile/ProfileContainer';
import { useSelector } from 'react-redux';
import { selectCitySessions, selectCurrentTopArtists } from '../liveSessionSlice';

const CardBottom = ({ artist }) => {
  return (
    <div className={`${styles.cardBottom}`}>
      <div style={{ padding: 5 }}>
        <div className={`${styles.artistName} `}>{artist.artistName}</div>
        <div className={styles.detailContainer}>
          <div className={styles.detailContainerItem}>
            <img src="/assets/fire-small.png" className={styles.detailContainerItemImg} alt="" />{' '}
            <label className={styles.detailContainerItemValue}> {artist.votes || 0}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

const ArtistCardTopLabel = () => {
  const citySession = useSelector(selectCitySessions);

  return (
    <div className={styles.artistCardTopLabelContainer}>
      <img className={styles.artistCardTopLabelImage} src="/assets/fire-big.png" alt="" />
      <div className={styles.artistCardTopLabelTextcontainer}>
        <li> Current </li>
        <li> {citySession && citySession[0].status === 'live' ? 'Leader' : 'Winner'} </li>
      </div>
    </div>
  );
};

function SessionArtistCard({ cityId, onViewCitySessionClick }) {
  const [artistProfileModalOpen, setArtistProfileModalOpen] = useState(false);

  const citySessions = useSelector(selectCitySessions);
  const citySession = citySessions && citySessions.find((cs) => cs.id === cityId);

  const topArtists = useSelector(selectCurrentTopArtists);
  const artist = (topArtists && topArtists[cityId] && topArtists[cityId].top1) || {};

  const handleArtistCardTap = () => {
    setArtistProfileModalOpen(true);
  };
  const { totalHistoricalVotes, shares } = artist;

  return (
    <div className={styles.artistCardContainer}>
      <div className={`${styles.cardBorder}`}></div>

      <div className={styles.artistCard} onClick={() => handleArtistCardTap(artist)}>
        <div className={`${styles.imageStackContainer} imageStackContainer`}>
          <img
            className={`${styles.imageStackBackground}`}
            src={citySession && citySession.eventBackgroundUrl}
            alt=""
          />
          <img
            className={`${styles.imageStackCutout}`}
            src={`${artist.profileAssets && artist.profileAssets.cutoutUrl}`}
            alt=""
          />
        </div>

        <ArtistCardTopLabel />
        <CardBottom artist={artist} votes={totalHistoricalVotes} shares={shares} />
      </div>
      <ProfileModal
        fromliveSession={true}
        open={artistProfileModalOpen}
        onClose={() => setArtistProfileModalOpen(false)}
        artistId={artist.id}
        isCurrentUser={false}
        position={artist.position}
      />
      <div className={`${styles.swipeIndicator}`} onClick={onViewCitySessionClick}>
        <div className={`${styles.swipeIndicatorContent}`}>
          <img
            style={{ marginTop: 7, width: 10, height: 11 }}
            src="/assets/chevron-up.png"
            alt=""
          />
          <label> View City Session</label>
        </div>
      </div>
    </div>
  );
}

export default SessionArtistCard;
