import firebase from 'firebase/app';
import 'firebase/auth';
import { setAnalyticsForUser } from './analytics';

export const signUpWithGoogle = async () => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const result = await firebase.auth().signInWithPopup(googleProvider);
  setAnalyticsForUser(result.user.uid);
  await storeToken();
  return { id: result.user.uid, ...result.user.toJSON() };
};

export const signUpWithFacebook = async () => {
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  const result = await firebase.auth().signInWithPopup(facebookProvider);
  const token = result.credential.accessToken;
  setAnalyticsForUser(result.user.uid);
  await storeToken();
  return { id: result.user.uid, fbToken: token, ...result.user.toJSON() };
};

export const signUpWithApple = async () => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  provider.addScope('email');
  provider.addScope('name');
  const result = await firebase.auth().signInWithPopup(provider);
  const token = result.credential.accessToken;
  setAnalyticsForUser(result.user.uid);
  await storeToken();
  return { id: result.user.uid, fbToken: token, ...result.user.toJSON() };
};

export const logOff = async () => {
  await firebase.auth().signOut();
  clearToken();
};

export const storeToken = async () => {
  const token = await firebase.auth().currentUser.getIdToken();
  localStorage.setItem('token', token);
};

export const clearToken = () => localStorage.setItem('token', '');

export const getToken = () => localStorage.getItem('token');
