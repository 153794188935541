import React, { useEffect } from 'react';
import { stripePromise } from '../../api/ecommerce.api';
import CheckoutForm from './checkout';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector, useDispatch } from 'react-redux';
import { createPaymentIntent, platformSet, selectClientSecret } from './ecommerceSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { selectCurrentUser } from '../auth/authSlice';
import { trackUserBeginCheckout } from '../../api/analytics';

export function Ecommerce() {
  const dispatch = useDispatch();
  const clientSecret = useSelector(selectClientSecret);
  const { userId, productId, platform } = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const customerId = (currentUser && currentUser.id) || userId;

  useEffect(() => {
    if(productId) {
      trackUserBeginCheckout(productId);

    }
  }, []);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (customerId && productId) {
      dispatch(createPaymentIntent({ customerId, productId, platform: platform || 'web' }));
    }
  }, [customerId, productId]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (platform) {
      dispatch(platformSet(platform));
    }
  }, [platform]);

  const appearance = {
    theme: 'night',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    clientSecret && (
      <Elements options={options} stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    )
  );
}
