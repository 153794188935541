import React, { useEffect, useState, useRef } from 'react';
import styles from './LiveSession.module.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCitySessions,
  sliderMovingChange,
  currentCityOnSliderChange,
  selectCurrentCitySession,
  selectCurrentCitySessionIndex,
} from './liveSessionSlice';
import { selectCurrentUser } from '../auth/authSlice.js';
import { toggleModalVisibility, selectModalVisibility } from '../loginModal/loginModalSlice';
import '../../utils/slick.css';
import '../../utils/slickTheme.css';
import LogoSrc from '../../assets/headlightsLogo.png';
import SwiperCore, { Mousewheel } from 'swiper';
import { Swiper } from 'swiper/react';
import { SwiperSlide } from 'swiper/react';
import './LiveSession.css';

SwiperCore.use([Mousewheel]);

const HeadlightsLogo = () => (
  <div className={styles.logoContainer}>
    <img className={styles.logo} src={LogoSrc} alt="" />
  </div>
);

export const SessionStatusLabel = () => {
  const currentSessions = useSelector(selectCitySessions);

  return (
    <div className={styles.sessionStatusLabel}>
      <img className={styles.sessionStatusIndicator} src="/assets/indicator.png" alt="" />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <label> {currentSessions && (currentSessions[0].status || '').toUpperCase()} </label>
      </div>
    </div>
  );
};

const MODAL_STEPS = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  GO_BACK: 'GO_BACK',
  AVAILABLE: 'AVAILABLE',
};

export function CityHorizontalNavigation({ children }) {
  const dispatch = useDispatch();

  const open = useSelector(selectModalVisibility);
  const currentUser = useSelector(selectCurrentUser);
  const currentCitySession = useSelector(selectCurrentCitySession);
  const citySessionIndex = useSelector(selectCurrentCitySessionIndex);
  const citySessions = useSelector(selectCitySessions);

  const isFirstLoad = useRef(true);
  const topSwiperRef = useRef();
  const bottomSwiperRef = useRef();

  const [bottomSwiper, setBottomSwiper] = useState();
  const [direction, setDirection] = useState('');
  const [modalStep, setModalStep] = useState(MODAL_STEPS.AVAILABLE);
  const [firstRender, setFirstRender] = useState(true);

  const handleSlide = (directionSlide) => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;
      return;
    }

    if (modalStep === MODAL_STEPS.CLOSE) {
      setModalStep(MODAL_STEPS.AVAILABLE);
      return;
    }

    setModalStep(MODAL_STEPS.OPEN);
    setDirection(directionSlide);
  };

  useEffect(() => {
    if (modalStep === MODAL_STEPS.OPEN && !currentUser) {
      dispatch(toggleModalVisibility({ isOpen: true, message: 'You need to sign in!' }));
    }
    if (modalStep === MODAL_STEPS.GO_BACK) {
      direction === 'right'
        ? bottomSwiperRef.current.swiper.slidePrev()
        : direction === 'left' && bottomSwiperRef.current.swiper.slideNext();

      setModalStep(MODAL_STEPS.CLOSE);
      setDirection('');
    }
  }, [direction, modalStep, currentCitySession]);

  useEffect(() => {
    if (isFirstLoad.current) return;
    if (!open && modalStep === MODAL_STEPS.OPEN) {
      setModalStep(MODAL_STEPS.GO_BACK);
    }
  }, [open]);

  const handleSlideNext = () => {
    if (topSwiperRef.current.swiper) {
      topSwiperRef.current.swiper.slideNext();
    }
    handleSlide('right');
  };

  const handleSlidePrev = () => {
    if (topSwiperRef.current.swiper) {
      topSwiperRef.current.swiper.slidePrev();
    }
    handleSlide('left');
  };

  const handleSwipedToCitySession = (swiper) => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    let currentIndex = 0;
    if (swiper.activeIndex !== 0) {
      currentIndex = swiper.activeIndex > citySessions.length ? 0 : swiper.activeIndex - 1;
    } else {
      currentIndex = citySessions.length - 1;
    }

    dispatch(currentCityOnSliderChange(currentIndex));
  };

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
      }}
    >
      <HeadlightsLogo />
      <Swiper
        ref={topSwiperRef}
        style={{
          zIndex: 2,
          width: '100vw',
        }}
        initialSlide={citySessionIndex - 1}
        spaceBetween={0}
        slidesPerView={5}
        centeredSlides={true}
        allowTouchMove={false}
        loop={true}
      >
        {(citySessions || []).map((city, index) => (
          <SwiperSlide key={city.id} virtualIndex={index}>
            {({ isActive }) => (
              <div
                style={{ color: isActive ? 'white' : 'rgba(169,169,169,0.5)' }}
                className={styles.cityTab}
              >
                {city.acronym}
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      <span className={styles.tabInkBar}></span>
      <div className={styles.topGradient} />

      <Swiper
        ref={bottomSwiperRef}
        style={{
          zIndex: 0,
          position: 'absolute',
          top: 0,
          width: '100vw',
          height: '100vh',
        }}
        initialSlide={citySessionIndex}
        spaceBetween={0}
        slidesPerView={1}
        centeredSlides={true}
        loop={true}
        onSlideChange={handleSwipedToCitySession}
        onSliderMove={() => dispatch(sliderMovingChange(true))}
        onSlideResetTransitionEnd={() => dispatch(sliderMovingChange(false))}
        onSlideChangeTransitionEnd={() => dispatch(sliderMovingChange(false))}
        onSlideNextTransitionEnd={handleSlideNext}
        onSlidePrevTransitionEnd={handleSlidePrev}
        onSwiper={setBottomSwiper}
      >
        {children}
      </Swiper>
      <div
        className={`${styles.swiperButtonNext} nav-button`}
        onClick={() => {
          handleSlide('right');
          bottomSwiper.slideNext();
        }}
      />
      <div
        className={`${styles.swiperButtonPrev} nav-button`}
        onClick={() => {
          handleSlide('left');
          bottomSwiper.slidePrev();
        }}
      />
    </div>
  );
}
