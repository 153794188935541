import { createSlice } from '@reduxjs/toolkit';
import { getArtistsByCity } from '../../api/artists.api';

const initialState = {
  artists: {},
  loading: false,
  error: null,
};

function startLoading(state) {
  state.loading = true;
}

function loadingFailed(state, action) {
  state.loading = false;
  state.error = action.payload;
}

export const ArtistsSlice = createSlice({
  name: 'artists',
  initialState,
  reducers: {
    cityArtistsLoading: startLoading,

    cityArtistsSuccess: (state, { payload }) => {
      const { artists, cityId, hasMoreItems } = payload;

      state.artists[cityId] = artists || [];
      state.hasMoreItems = hasMoreItems;
      state.loading = false;
      state.error = null;
    },

    cityArtistsFailure: loadingFailed,
  },
});

export const { cityArtistsLoading, cityArtistsSuccess, cityArtistsFailure } = ArtistsSlice.actions;

export default ArtistsSlice.reducer;

export const selectArtists = (state) => state.artists.artists;

//Thunk

export const getCityArtists =
  ({ cityId }) =>
  async (dispatch) => {
    try {
      dispatch(cityArtistsLoading());

      const artistsResult = await getArtistsByCity(cityId);
      dispatch(
        cityArtistsSuccess({
          cityId: cityId,
          artists: artistsResult.results,
          hasMoreItems: artistsResult.hasMoreItems,
        }),
      );
    } catch (err) {
      dispatch(cityArtistsFailure(err.toString()));
    }
  };
