import { createSlice } from '@reduxjs/toolkit';
import { getUserPurchases } from '../../api/ecommerce.api';

const initialState = {
  isLoading: false,
  products: [],
  productsLoaded: false,
};

export const userPurchasesSlice = createSlice({
  name: 'userPurchases',
  initialState,
  reducers: {
    loadingStarted: (state) => {
      state.isLoading = true;
    },
    loadSucceded: (state) => {
      state.isLoading = false;
    },

    productsLoaded: (state, { payload }) => {
      state.products = payload.products;
      state.isLoading = false;
      state.productsLoaded = true;
    },

  },
});

export const { loadingStarted, productsLoaded } = userPurchasesSlice.actions;

export default userPurchasesSlice.reducer;

export const selectPurchasesLoading = (state) => state && state.userPurchases.isLoading;
export const selectUserPurchases = (state) => state && state.userPurchases.products;
export const loadUserPurchases = (userId) => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    const products = await getUserPurchases(userId);
    dispatch(productsLoaded({ products:products.filter(p => p.status !== 'cancelled') }));
  } catch (err) {
    console.error(err);
  }
};
