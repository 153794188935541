import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const EventIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="5px"
      y="0px"
    >
      <title>Path</title>
      <g>
        <g>
          <g>
            <path
              d="M12.4653742,12.5697167 C13.0739557,11.5418611 13.4776681,10.2418367 13.5560003,8.95549667 C13.6057112,8.1298671 13.4234381,7.26318413 13.028764,6.44819804 C12.4096378,5.16794003 11.3461267,4.44874522 10.318769,3.75387834 C9.55201669,3.23538906 8.82744327,2.7457892 8.3152708,2.04636084 L8.22338103,1.92168013 C7.92059671,1.51114609 7.57864626,1.04587418 7.52441624,0.655106592 C7.47018621,0.259777517 7.11467826,-0.0215143245 6.72602973,0.0012931221 C6.33135564,0.0286620581 6.02555855,0.358609786 6.02555855,0.75850035 L6.02555855,14.3061237 C5.39438129,13.9199176 4.61708424,13.6827201 3.76597409,13.6827201 C1.69016917,13.6827201 0,15.0466054 0,16.723713 C0,18.4008206 1.69016917,19.7647059 3.76597409,19.7647059 C5.84177901,19.7647059 7.53194819,18.4008206 7.53194819,16.723713 L7.53194819,7.86225973 C8.6632468,8.29864221 10.5145997,9.41012511 11.0237594,12.0056125 C10.9288568,12.1470187 10.8384734,12.2990683 10.7330262,12.4207081 C10.4573569,12.7354508 10.4874847,13.2159277 10.8008137,13.4926581 C11.11113,13.7724294 11.5871491,13.7389785 11.8628184,13.4242357 C12.0646746,13.1931203 12.243935,12.9224719 12.4156634,12.6396596 C12.4337401,12.6183726 12.4503574,12.5955652 12.4653742,12.5697167 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
);

export const ProfileIcon = (props) => (
  <SvgIcon {...props}>
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
    >
      <title>Path</title>
      <g>
        <g>
          <g>
            <path
              d="M10,0 C4.48,0 0,4.48 0,10 C0,15.52 4.48,20 10,20 C15.52,20 20,15.52 20,10 C20,4.48 15.52,0 10,0 Z M10,3 C11.66,3 13,4.34 13,6 C13,7.66 11.66,9 10,9 C8.34,9 7,7.66 7,6 C7,4.34 8.34,3 10,3 Z M10,17.2 C7.5,17.2 5.29,15.92 4,13.98 C4.03,11.99 8,10.9 10,10.9 C11.99,10.9 15.97,11.99 16,13.98 C14.71,15.92 12.5,17.2 10,17.2 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  </SvgIcon>
);
