import React, { useEffect } from 'react';
import './product.css';
import Product from './Product';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { selectProducts, selectLoading, loadProducts } from './ecommerceSlice';
import signupStyles from '../auth/signupForm/SignUpForm.module.css';
import { SignUpForm } from '../auth/signupForm/SignUpForm';
import { selectCurrentUser } from '../auth//authSlice';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import './productList.css';
import {
  selectUserPurchases,
  loadUserPurchases,
  selectPurchasesLoading,
} from './userPurchasesSlice';


export default function ProductsList() {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const currentUser = useSelector(selectCurrentUser);
  const customerId = (currentUser && currentUser.id) || userId;
  const products = useSelector(selectProducts);
  const isLoading = useSelector(selectLoading);
  const userPurchases = useSelector(selectUserPurchases);
  const purchasesLoading = useSelector(selectPurchasesLoading);
  useEffect(() => {
    dispatch(loadProducts());
  }, []);
  useEffect(() => {
    dispatch(loadUserPurchases(customerId));
  }, [customerId]);

  if (isLoading || purchasesLoading) {
    return (
      <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return customerId ? (
    <div className="parent-container">
      <div className="scrollable-div">
        {products.map((product) => {
          const unconsumedProductIndex = userPurchases.findIndex(p => p.productId === product.id)
          

          if (product.disabledBecauseLiveBroadcast) {
            return <Product key={product.id} {...product} customerId={customerId} disabledMessage='avaiable during live broadcasts only'></Product>;
          }
          if (product.productType !== 'votesPurchase' && unconsumedProductIndex !== -1) {
            return <Product key={product.id} {...product} customerId={customerId} disabledMessage='already purchased!'></Product>;

          }
          return <Product key={product.id} {...product} customerId={customerId}></Product>;
        })}
      </div>
    </div>
  ) : (
    <div
      className={signupStyles.signInContainer}
      style={{ backgroundImage: "url('/assets/signupbg.png')" }}
    >
      <SignUpForm message={'You need to sign in order to purchase!'} />
    </div>
  );
}
