import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalVisibility, selectModalVisibility, modalMessage } from './loginModalSlice';

import { Modal } from '@material-ui/core';
import styles from './LoginModal.module.css';
import { SignUpForm } from '../auth/signupForm/SignUpForm';
import CancelIcon from '@material-ui/icons/Cancel';

export function LoginModal() {
  const dispatch = useDispatch();
  const showModal = useSelector(selectModalVisibility);
  const messageModal = useSelector(modalMessage);

  const closeModal = () => {
    dispatch(toggleModalVisibility({ isOpen: false }));
  };

  return (
    <div className={styles.modalContainer}>
      <Modal
        open={showModal}
        onClose={closeModal}
        style={{ backdropFilter: 'blur(5px)' }}
        BackdropProps={{
          // Overwrite the onClose function to prevent modal from closing when clicking outside of the modal
          onClick: () => {},
        }}
        className={styles.loginModalContainer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.loginModalCard}>
          <div onClick={closeModal}>
            <CancelIcon className={styles.cancelIcon} />
          </div>
          <div className={styles.contentContainer}>
            <SignUpForm message={messageModal} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
