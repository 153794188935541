import React, { useState } from 'react';
import { TopNavigationBar } from '../commonComponents/TopNavigationBar';
import { useDispatch, useSelector } from 'react-redux';
import { sendRequest, selectArtistRequestLoading, exampleModalTapped } from './artistRequestSlice';
import styles from './ArtistRequest.module.css';
import { TextBox } from '../commonComponents/TextBox';
import { ImageUploadInput } from '../commonComponents/ImageUploadInput';
import { LoadingIndicator } from '../commonComponents/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { ArtistRequestPictureExample } from './ArtistRequestPictureExample';
import { ArtistRequestModal } from './ArtistRequestModal';

export const ArtistRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [requestModel, setRequestModel] = useState({});

  const isLoading = useSelector(selectArtistRequestLoading);

  const setupPhotoUrl = (url) => {
    setRequestModel((requestModel) => ({
      ...requestModel,
      photoUrl: url,
    }));
  };

  const processRequest = () => {
    if (!requestModel.instagramUser || !requestModel.cityId || !requestModel.artistName) {
      alert('fill all the required items before submitting please');
      return;
    }

    if (!requestModel.photoUrl) {
      alert('missing profile picture');
      return;
    }

    dispatch(sendRequest(requestModel));
  };

  const exampleTapped = () => {
    dispatch(exampleModalTapped());
  };

  return (
    <div>
      <TopNavigationBar
        navBarTitle={'Artist Request'}
        rightButtonLabel={'send'}
        onRightButtonTapped={processRequest}
      />

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className={styles.formContainer}>
          <TextBox
            labelText={'Artist Name'}
            isMandatory={true}
            onChange={(value) => setRequestModel({ ...requestModel, artistName: value })}
          />
          <TextBox
            labelText={'City'}
            isMandatory={true}
            onChange={(value) => setRequestModel({ ...requestModel, cityId: value })}
          />
          <TextBox
            labelText={'Instagram Profile '}
            isMandatory={true}
            onChange={(value) => setRequestModel({ ...requestModel, instagramUser: value })}
          />
          <label className={styles.attachText}>
            Attach your image presentation{' '}
            <button onClick={exampleTapped} className={styles.exampleButton}>
              {' '}
              Example{' '}
            </button>
          </label>
          <label className={styles.maxText}>
            Max 25mb with clear contrast with the background{' '}
          </label>
          <ImageUploadInput onChange={setupPhotoUrl} />
        </div>
      )}

      <ArtistRequestPictureExample />
      <ArtistRequestModal onAcceptedClicked={() => history.goBack()} />
    </div>
  );
};
