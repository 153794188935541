import React from 'react';
import { selectArtistRequestSent } from './artistRequestSlice';
import { useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import styles from './ArtistRequestPictureExample.module.css';
import { Modal } from '@material-ui/core';

export const ArtistRequestModal = ({ onAcceptedClicked }) => {
  const requestSent = useSelector(selectArtistRequestSent);
  const closeModal = () => {
    onAcceptedClicked();
  };

  return requestSent ? (
    <Modal open={requestSent !== undefined}>
      <div className={styles.container}>
        <Typography variant="h5" gutterBottom className={styles.exampleTitle}>
          AWESOME!
        </Typography>
        <label>
          {' '}
          Your information has been received.
          <br /> <br />
          We will contact you very soon.
        </label>
        <button className={styles.gotItButton} onClick={closeModal}>
          Got it
        </button>
      </div>
    </Modal>
  ) : null;
};
