import { getRequest, postRequest } from './authHttpClient';
import { config } from '../utils/apiConfig';
const resourceUrl = `${config.API_URL}/currentSession`;
const citySessionUrl = `${config.API_URL}/citySession`;
const castVoteUrl = `${config.API_URL}/eventvoting`;

export const getLiveSession = async () => {
  const result = await getRequest(resourceUrl);
  return result.data;
};

export const getCitySessionById = async (cityId) => {
  const result = await getRequest(`${citySessionUrl}/${cityId}`);
  return result.data;
};

export const castEventVote = async (competitionId, cityId, votedArtistId, voterId) => {
  const body = {
    competitionId,
    votedArtistId,
    cityId,
    voterId,
  };
  const result = await postRequest(castVoteUrl, body);
  return result.data;
};
