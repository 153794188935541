import React, { useRef } from 'react';
import styles from './ContestantVideo.module.css';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import HLSSource from '../../../utils/HLSSource';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSession,
  selectVideoMute,
  videoMuteToggle,
  selectSliderMoving,
} from '../liveSessionSlice';
import { isMobileSafari } from 'react-device-detect';
import EventVoteButton from '../../voteButton/EventVoteButton';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import './citySession.css';
import { ShareProfileButton } from '../../artistProfile/ProfileContainer';

export const VideoSideBar = ({ votes, contestantId, cityId }) => {
  const dispatch = useDispatch();
  const videoUnmuted = useSelector(selectVideoMute);
  const liveSession = useSelector(selectSession);
  const { vendors } = liveSession || [];
  const isVendor = vendors?.filter((v) => v.id == contestantId).length;

  return (
    <div className={styles.videoSideBar}>
      <div className={styles.videoSideBarButton} onClick={() => dispatch(videoMuteToggle())}>
        {videoUnmuted ? (
          <VolumeOffIcon className={styles.volumeIcon} />
        ) : (
          <VolumeUpIcon className={styles.volumeIcon} />
        )}
      </div>
      <div className={styles.videoSideBarButton}>
        <ShareProfileButton artistId={contestantId} cityId={cityId} />
      </div>
      {!isVendor && (
        <>
          <div className={styles.videoSideBarButton}>
            {liveSession.status !== 'ended' && (
              <EventVoteButton
                sessionArtistVotesTotal={votes}
                contestantId={contestantId}
                cityId={cityId}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export const ContestantVideoPlaceholder = function ({ contestant }) {
  const { defaultVideo } = contestant;
  const { thumbnailUrl } = defaultVideo;
  const isSliderMoving = useSelector(selectSliderMoving)

  return (
    <div className="video-placeholder-container">
      {<img className={styles.placeHolder} src={thumbnailUrl} alt="" />}
      {isSliderMoving && <div className={styles.containerVotesSidebar}>
        <VideoSideBar
          votes={contestant.votes}
          contestantId={contestant.id}
          cityId={contestant.cityId}
        />
      </div>}
    </div>
  );
};

export const ContestantVideo = function ({ contestant }) {
  const player = React.createRef();
  const ref = useRef();
  const videoMute = useSelector(selectVideoMute);
  const { defaultVideo } = contestant || {};
  const { streamingUrl, assetType, thumbnailUrl } = defaultVideo || {};
  const isSliderMoving = useSelector(selectSliderMoving)

  return (
    <div ref={ref} className={styles.video}>
      {isMobileSafari ? (
        <video
          className={styles.safariVideo}
          autoPlay
          loop
          muted={videoMute}
          playsInline
          style={{ position: 'absolute', height: '100%' }}
          src={streamingUrl}
        ></video>
      ) : (
        <Player ref={player} muted={videoMute} playsInline={true} autoPlay={true} loop>
          <HLSSource isVideoChild src={streamingUrl || ''} />
          <BigPlayButton position="center" className={styles.bigPlayButton} />
          <ControlBar disableCompletely={true} />
        </Player>
      )}
    {!isSliderMoving && assetType === 'song' && <div className="video-placeholder-container">
      <img className={styles.placeHolder} src={thumbnailUrl} alt="" />
    </div>}
    </div>
  );
};
