import { localFunctionsUrl } from './localConfig';

const prod = {
  API_URL: '/jointapi',
};
const dev = {
  API_URL: `${localFunctionsUrl}/jointapi`,
};

export const config =
  process.env.REACT_APP_STAGE === 'dev' ||
  process.env.REACT_APP_STAGE === 'devStg' ||
  process.env.REACT_APP_STAGE === 'devProd'
    ? dev
    : prod;
