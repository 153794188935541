import React from 'react';

import { ArtistCell } from './ArtistCell';
import styles from '../ArtistsTab.module.css';

export function ArtistsGrid({ artists }) {
  return artists && artists.length > 0 ? (
    <div className={styles.artistListWrapper}>
      {artists.map((artist, index) => (
        <ArtistCell key={index} artist={artist} />
      ))}
    </div>
  ) : (
    <label
      style={{
        color: 'white',
        fontWheight: 'bold',
        marginTop: '100px',
        fontSize: '24pt',
        width: 'auto',
        height: 'auto',
        display: 'block',
        textAlign: 'center',
      }}
    >
      {' '}
      No Artists Found
    </label>
  );
}
