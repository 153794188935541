import React from 'react';
import { useDispatch } from 'react-redux';
import { signUpWithGoogle, signUpWithFacebook, signUpWithApple } from '../authSlice';
import styles from './SignUpForm.module.css';
import Typography from '@material-ui/core/Typography';

export const SignUpForm = ({ message }) => {
  const dispatch = useDispatch();
  let ua = navigator.userAgent || navigator.vendor || window.opera;
  const isInstagram = ua.indexOf('Instagram') > -1
  const isFacebook = (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1)

  return (isInstagram || isFacebook) ? (
    <>
      <a href="https://apps.apple.com/br/app/headlights-hip-hop/id1526222689?l=en-GB">
        <div className={styles.itemsContainer}>
          <Typography variant="h4" gutterBottom className={styles.howSignInTypography}>
            Download the app to experience live voting
          </Typography>
        </div>
      </a>
    </>
  ) : (
    <>
      <div className={styles.itemsContainer}>
        <Typography variant="subtitle1" gutterBottom className={styles.signInTypography}>
          {message}
        </Typography>
        <Typography variant="h4" gutterBottom className={styles.howSignInTypography}>
          How do you want to sign in?
        </Typography>
        <div className={styles.signUpButtonsContainer}>
          <button className={styles.signInButton} onClick={() => dispatch(signUpWithGoogle())}>
            <img className={styles.singUpButtonImage} src="/assets/google.png" alt="" />
            <span> Sign in with Google</span>
          </button>
          <button className={styles.signInButton} onClick={() => dispatch(signUpWithFacebook())}>
            <img className={styles.singUpButtonImage} src="/assets/facebook.png" alt="" />
            <span> Sign in with Facebook</span>
          </button>
          <button className={styles.signInButton} onClick={() => dispatch(signUpWithApple())}>
            <img className={styles.singUpButtonImage} src="/assets/apple.png" alt="" />
            <span> Sign in with Apple</span>
          </button>
        </div>
      </div>
    </>
  );
};
