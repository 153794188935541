import { CircularProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import SubscriptionCanceledPage from './subscriptionCanceledPage';
import SubscriptionSuccesPage from './SubscriptionSuccesPage';

export default function SubscriptionFinalStep() {
  const { search }   = useLocation();
  const { productId } = useParams()
  const queryParams = new URLSearchParams(search);
  const success = queryParams.get('success');
  const session_id = queryParams.get('session_id');
  const canceled = queryParams.get('canceled');  

  return ( 
    <div style={{backgroundColor: '#2B333F'}}>
      {canceled ?
      <SubscriptionCanceledPage></SubscriptionCanceledPage> :
      <SubscriptionSuccesPage productId={productId}></SubscriptionSuccesPage>}
    </div>
    );
};
