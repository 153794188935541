import React, { useEffect, useState } from 'react';
import styles from './OpenNativeModal.module.css';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import LogoIconSrc from '../../assets/logoIcon.png';
import CancelIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import constants from '../../utils/constants';
import useLocalStorage from '../../utils/hooks/UseLocalStorage';
import { useDetectOS } from '../../utils/hooks/OpenNativeModal';
import { useLocation } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BottomDialog = ({ onClose, open }) => {
  const operatingSystem = useDetectOS();

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      window.deferredPrompt = event;
    });
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleOpenNativeApp = async () => {
    window.location.href = constants.appStoreUrl;
  };

  const handleOpenPWA = async () => {
    if ('Notification' in window) {
      if (Notification.permission === 'default') {
        Notification.requestPermission().then((permission) => {
          console.info(permission);
        });
      }
    }
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      return;
    }
    promptEvent.prompt();
    await promptEvent.userChoice;
    window.deferredPrompt = null;
  };

  return (
    <>
      {operatingSystem === 'iOS' ? (
        <Dialog
          classes={{
            paper: styles.dialog,
          }}
          onClose={handleClose}
          open={open}
          fullScreen={true}
          disableBackdropClick={true}
          TransitionComponent={Transition}
          style={{ marginBottom: '200px' }}
        >
          <DialogContent style={{ marginBottom: '200px' }}>
            <div className={styles.dialogContentContainer}>
              <span className={styles.closeDialog} onClick={handleClose}>
                <CancelIcon />
              </span>
              <div className={styles.openNativeAppTextContainer}>
                <div>
                  <img className={styles.logoIcon} src={LogoIconSrc} alt="" />
                </div>
                <div>
                  <Typography>Use the app for a</Typography>
                  <Typography className={styles.highlightText}>better experience</Typography>
                </div>
              </div>
              <div className={styles.dialogButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenNativeApp}
                  className={styles.button}
                >
                  <span className={styles.textButton}>OPEN THE APP</span>
                </Button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        operatingSystem === 'Android' && (
          <Dialog
            classes={{
              paper: styles.dialogAndroid,
            }}
            onClose={handleClose}
            open={open}
            fullScreen={true}
            TransitionComponent={Transition}
            style={{ marginBottom: '200px' }}
          >
            <DialogContent style={{ marginBottom: '200px' }}>
              <div className={styles.dialogContentContainer}>
                <span className={styles.closeDialog} onClick={handleClose}>
                  <CancelIcon />
                </span>
                <div className={styles.openNativeAppTextContainer}>
                  <div>
                    <img className={styles.logoIcon} src={LogoIconSrc} alt="" />
                  </div>
                  <div>
                    <Typography>Use the app for a</Typography>
                    <Typography className={styles.highlightText}>better experience</Typography>
                  </div>
                </div>
                <div className={styles.dialogButton}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenPWA}
                    className={styles.button}
                  >
                    <span className={styles.textButton}>ADD TO HOME SCREEN</span>
                  </Button>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        )
      )}
    </>
  );
};

export function OpenNativeModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [openNativeModalShown, setOpenNativeModalShown] = useLocalStorage('openNativeModalShown');
  const location = useLocation();

  useEffect(() => {
    //if (!openNativeModalShown || operatingSystem === 'iOS') {
    const modalTimer = setTimeout(() => {
      if (!location.pathname.includes('ecommerce')) {
        setIsOpen(true);

        setOpenNativeModalShown(true);
      }
    }, 3000);

    return () => {
      clearTimeout(modalTimer);
    };
    // }
  }, [openNativeModalShown]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className={styles.modalContainer}>
      <BottomDialog onClose={handleClose} open={isOpen} />
    </div>
  );
}
