import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import liveSessionReducer from '../features/liveSession/liveSessionSlice';
import artistProfileReducer from '../features/artistProfile/artistProfileSlice';
import artistRequestReducer from '../features/artistRequest/artistRequestSlice';
import cityNavigationReducer from '../features/cityNavigation/cityNavigationSlice';
import leaderBoardReducer from '../features/leaderBoard/leaderBoardSlice';
import redirectModalReducer from '../features/redirectToProfileModal/redirectToProfileModalSlice';
import artistsReducer from '../features/artistsTab/ArtistsTabSlice';
import loginModalReducer from '../features/loginModal/loginModalSlice';
import endOfStreamReducer from '../features/liveSession/notification/EndOfStreamSlice';
import ecommerceReducer from '../features/ecommerce/ecommerceSlice';
import userPurchasesReducer from '../features/ecommerce/userPurchasesSlice';
import singleProductReducer from '../features/ecommerce/singleProductSlice';

export default configureStore({
  reducer: {
    auth: authReducer,
    liveSession: liveSessionReducer,
    artistProfile: artistProfileReducer,
    artistRequest: artistRequestReducer,
    cityNavigation: cityNavigationReducer,
    leaderBoard: leaderBoardReducer,
    redirectModal: redirectModalReducer,
    loginModal: loginModalReducer,
    artists: artistsReducer,
    endOfStreamModal: endOfStreamReducer,
    ecommerce: ecommerceReducer,
    userPurchases: userPurchasesReducer,
    singleProduct: singleProductReducer,
  },
});
