import firebase from 'firebase/app';
import 'firebase/auth';
const axios = require('axios');

export const postRequest = async (url, body, params) => {
  const httpVerb = 'post';
  return authClient(url, body, params, httpVerb);
};

export const putRequest = async (url, body, params) => {
  const httpVerb = 'put';
  return authClient(url, body, params, httpVerb);
};

const authClient = async (url, body, params, verb) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return axios({
      method: verb,
      url: url,
      data: body,
      params: params,
    });
  }

  let headers = { Authorization: 'Bearer ' + token };
  try {
    let result = await axios({
      method: verb,
      url: url,
      data: body,
      headers: headers,
      params: params,
    });
    return result;
  } catch (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      if (localStorage.getItem('loggedIn')) {
        let newToken = await firebase.auth().currentUser.getIdToken();
        localStorage.setItem('token', newToken);
        headers = { Authorization: 'Bearer ' + token };
        try {
          let retryResult = axios({
            method: verb,
            url: url,
            data: body,
            headers: headers,
            params: params,
          });
          return retryResult;
        } catch (retryError) {
          localStorage.setItem('token', '');
          await firebase.auth().signOut();
          throw new Error('Error authenticating with firebase, logging out...');
        }
      } else {
        localStorage.setItem('token', '');
        await firebase.auth().signOut();
        throw new Error('Error authenticating with firebase, logging out...');
      }
    } else {
      throw error;
    }
  }
};

export const getRequest = async (url, params) => {
  const verb = 'get';
  return authClient(url, null, params, verb);
};
