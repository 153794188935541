import React from 'react';
import './product.css';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModalVisibility } from '../loginModal/loginModalSlice';
import { trackUserViewItem } from '../../api/analytics';
import { useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { createStripeCheckoutSession, selectIsCheckoutSessionLoading, selectCheckoutSession, selectPlatform } from './ecommerceSlice';

export default function SingleProduct({
  id: productId,
  name,
  price,
  descriptionItems,
  buttonText,
  timeRange,
  disabledMessage,
  customerId,
  productType,
  priceId
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isCheckoutSessionLoading = useSelector(selectIsCheckoutSessionLoading)
  const checkoutSessionLink = useSelector(selectCheckoutSession)
  const platform = useSelector(selectPlatform)


  useEffect(() => {
    trackUserViewItem(productId, name);
  }, []);

  const handlePurchase = (productType) => {
    if (!customerId) {
      dispatch(
        toggleModalVisibility({ isOpen: true, message: 'You need to sign in order to vote!' }),
      );
      return;
    }
    if (productType === 'monthlySubscription') {

      const metadata = {
        productId,
        customerId,
        platform,
        price
      }
      dispatch(createStripeCheckoutSession({ userId:customerId, priceId, metadata }))

    } else {
      const newRoute = location.pathname.replace('/product', `/${customerId}`);
      history.push(newRoute);
    }
  };

  useEffect(() => {
    if (checkoutSessionLink) {
      window.location.href = checkoutSessionLink;
    }
  }, [checkoutSessionLink]); 

  if (isCheckoutSessionLoading) {
    return <CircularProgress></CircularProgress>
  }

  return ( isCheckoutSessionLoading ? 
  <CircularProgress></CircularProgress> :
    <div className="product">
      <h2>{name}</h2>
      <h2>
        ${price}
        {timeRange ? `/${timeRange}` : ''}
      </h2>
      {descriptionItems && descriptionItems.map((item) => <p key={item}>- {item}</p>)}
      {disabledMessage ? (
        <button style={{ backgroundColor: 'grey' }} disabled onClick={handlePurchase}>
          {disabledMessage}
        </button>
      ) : (
        <button onClick={() => handlePurchase(productType)}>{buttonText || 'Purchase'}</button>
      )}
    </div>
  );
}
