export const upperCaseName = (name) =>
  (name ?? '')
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
    .join(' ');

export const getYTThumb = (url, size) => {
  if (url === null) {
    return '';
  }

  const results = url.match('[\\?&]v=([^&#]*)');
  const video = results === null ? url : results[1];

  if (size === 'small') {
    return 'https://img.youtube.com/vi/' + video + '/2.jpg';
  }
  return 'https://img.youtube.com/vi/' + video + '/0.jpg';
};

export const getDiffInSeconds = (t1, t2) => {
  if (!t1 || !t2) return null;
  var dif = t1.getTime() - t2.getTime();

  var Seconds_from_T1_to_T2 = dif / 1000;
  var Seconds_Between_Dates = Math.abs(Seconds_from_T1_to_T2);

  return Seconds_Between_Dates;
};
