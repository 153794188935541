import React from 'react';
import { Link } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { useLocation } from 'react-router-dom';
import styles from './Navigation.module.css';
import { ProfileIcon, EventIcon } from './navIcons';

export function AppBottomNavigation() {
  const location = useLocation();

  let currentLocation = 0;
  const currentLocationString =
    location.pathname.split('/') &&
    location.pathname.split('/').length > 0 &&
    location.pathname.split('/')[1];
  switch (currentLocationString) {
    case 'session':
      currentLocation = 0;
      break;
    case '':
      currentLocation = 0;
      break;
    case 'artists':
      currentLocation = 0;
      break;
    case 'profile':
      currentLocation = 1;
      break;
    default:
      currentLocation = 0;
  }

  const shouldHideBottomNavigation = ['ecommerce'].includes(currentLocationString);

  const [value, setValue] = React.useState(currentLocation);
  return (
    <>
      {shouldHideBottomNavigation ? null : (
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          className={styles.root}
        >
          <BottomNavigationAction component={Link} to="/" label="Session" icon={<EventIcon />} />
          <BottomNavigationAction
            component={Link}
            to="/profile"
            label="Profile"
            icon={<ProfileIcon />}
          />
        </BottomNavigation>
      )}
    </>
  );
}
