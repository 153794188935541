import { createSlice } from '@reduxjs/toolkit';
import { sendArtistRequest } from '../../services/profile.service';

const initialState = {
  isLoading: false,
  requestSent: false,
  requestFailed: false,
  exampleModalShown: false,
};

export const artistRequestSlice = createSlice({
  name: 'artistRequest',
  initialState,
  reducers: {
    loadingStarted: (state) => {
      state.isLoading = true;
    },
    requestSent: (state) => {
      state.requestSent = true;
      state.isLoading = false;
    },
    requestFailed: (state) => {
      state.requestSent = false;
      state.isLoading = false;
      state.requestFailed = true;
    },
    exampleModalTapped: (state) => {
      state.exampleModalShown = true;
    },
    exampleModalClosed: (state) => {
      state.exampleModalShown = false;
    },
  },
});

export const {
  loadingStarted,
  requestSent,
  requestFailed,
  exampleModalTapped,
  exampleModalClosed,
} = artistRequestSlice.actions;

export default artistRequestSlice.reducer;

export const selectArtistRequestLoading = (state) => state && state.artistRequest.isLoading;
export const selectArtistRequestSent = (state) => state && state.artistRequest.requestSent;

export const selectExampleModalTapped = (state) => state && state.artistRequest.exampleModalShown;

export const sendRequest = (request) => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    await sendArtistRequest(request);
    dispatch(requestSent());
  } catch (err) {
    console.error(err);
    dispatch(requestFailed(err.toString()));
  }
};
