import React from 'react';
import './NotificationModal.css'; // Estilos para el modal

// Componente de modal
export function NotificationModal({ isOpen, onClose, notification }) {
  if (!isOpen) {
    return null; // Si el modal no está abierto, no renderizar nada
  }
  const { data } = notification

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Congratulations</h2>
        <h6>You earned vote bonus</h6>    
        <h3>{data.congratulationMessage}</h3>
        <div className='voteButtonContainer'>
          <h2>+{data.votesReward}</h2>
        </div>
        <p>Spend your Bonus Votes to boost your favorite artists & rank up to win prizes</p>
        <button onClick={() => onClose(notification.id)}>Got it</button>
      </div>
    </div>
  );
}
