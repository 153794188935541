import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';
import './ButtonStyles.css';
import { useSelector } from 'react-redux';
import { selectPlatform } from './ecommerceSlice';

export default function CheckoutForm() {
  const platform = useSelector(selectPlatform);
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setIsEmailValid(emailRegex.test(emailValue));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/ecommerce/checkoutLastStep/${platform}`,
        receipt_email: email,
      },
    });
    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <div style={{ overflowY: 'auto', height: '90%', padding: '16px' }}>
      <form style={{ height: '160%' }} id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement
          id="payment-element"
          options={{
            layout: 'tabs',
            business: { name: 'Headlights' },
          }}
        />

        <TextInput
          label="Email Address"
          type="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter your email"
          error={isEmailValid}
        />

        <AddressElement
          options={{
            mode: 'billing',
            allowedCountries: ['US'],
            fields: {
              phone: 'always',
            },
            validation: {
              phone: {
                required: 'always',
              },
            },
          }}
        />
        <button
          className="stripe-pay-button"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && (
          <div style={{ color: 'white' }} id="payment-message">
            {message}
          </div>
        )}
      </form>
    </div>
  );
}

const TextInput = ({ label, value, onChange, type = 'text', placeholder, error }) => {
  const inputStyle = {
    backgroundColor: '#30313d',
    border: error ? '1px solid #424353' : '1px solid red',
    borderRadius: '4px',
    padding: '10px 16px',
    marginBottom: '16px',
    fontSize: '16px',
    lineHeight: '24px',
    width: '100%', // Width relative to parent
    maxWidth: '100%', // Prevents exceeding the parent container
    boxSizing: 'border-box', // Includes padding and border in the element's total width and height
    color: 'white',
    transition: 'box-shadow 150ms ease',
    outline: 'none',
    marginTop: '0.25rem',
  };

  return (
    <div style={{ marginTop: 16 }}>
      {label && (
        <label
          style={{
            fontSize: '0.93rem',
            color: 'white',
            fontWeight: 'normal',
            fontFamily:
              "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
          }}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={inputStyle}
      />
    </div>
  );
};
