import { createSlice } from '@reduxjs/toolkit';
import {
  handleGoogleSignUp,
  handleFacebookSignUp,
  getUserCurrentUser,
  signOut,
  handleAppleSignUp,
} from '../../services/profile.service';
import { firestoreDb } from '../../api/firebaseConfig';
import { toggleModalVisibility } from '../loginModal/loginModalSlice';
import { markBonusAsRead } from '../../api/bonusNotifications.api';

const initialState = {
  user: null,
  loading: false,
  error: null,
  lastVotedTime: null,
  availableVotesLoading: false,
  setEventAvailableVotesLastCallTime: 0,
  bonusNotifications: [],
};

function startLoading(state) {
  state.loading = true;
}

function loadingFailed(state, action) {
  state.loading = false;
  state.error = action.payload;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signInStart: startLoading,
    signInSuccess: (state, { payload }) => {
      const { user } = payload;

      state.user = user;
      state.loading = false;
      state.error = null;
    },
    signInFailure: loadingFailed,
    signUpStart: startLoading,
    signUpSuccess: (state) => {
      state.loading = false;
      state.error = null;
    },
    logOffStart: startLoading,
    logOff: (state) => {
      state.loading = false;
      state.error = null;
      state.user = null;
    },
    signUpFailure: loadingFailed,
    setLeaderboardAvailableVotes: (state, { payload }) => {
      state.availableVotesLoading = false;
      if (state.user) {
        if (!state.user.leaderboardAvailableVotes) {
          state.user.leaderboardAvailableVotes = payload.availableVotes;
        } else {
          //const now = new Date()
          // if (getDiffInSeconds(now, state.lastVotedTime) > 10)
          state.user.leaderboardAvailableVotes = payload.availableVotes;
        }
      }
    },

    setEventAvailableVotes: (state, { payload }) => {
      state.availableVotesLoading = false;
      if (state.user) {
        const currentTime = Date.now();
        state.user.eventAvailableVotes = payload.availableVotes;
        if (
          state.setEventAvailableVotesLastCallTime === 0 ||
          currentTime - state.setEventAvailableVotesLastCallTime >= 5000
        ) {
          state.user.localEventAvailableVotes =
            payload.availableVotes['theonecity'] + payload.availableVotes['totalbonus'] + (payload.availableVotes['purchasedVotes'] || 0);
        }
        state.setEventAvailableVotesLastCallTime = currentTime;
      }
    },

    setAvailableVotesLoading: (state) => {
      state.availableVotesLoading = true;
    },

    setBonusNotifications: (state, { payload }) => {
      state.bonusNotifications = payload.BonusNotifications
    },

    setNotificationRead: (state) => {
      state.bonusNotifications.shift()
    },

    userVotedOnEvent: (state, { payload }) => {
      const { cityId } = payload;
      if (state.user && state.user.localEventAvailableVotes > 0) {
        state.user.localEventAvailableVotes = state.user.localEventAvailableVotes - 1;
      }
      if (state.user && state.user.eventAvailableVotes[cityId] > 0) {
        state.user.eventAvailableVotes[cityId] = state.user.eventAvailableVotes[cityId] - 1;
      }
    },
  },
});

export const {
  signInStart,
  signInSuccess,
  signInFailure,
  signUpStart,
  signUpSuccess,
  signUpFailure,
  logOff,
  logOffStart,
  setLeaderboardAvailableVotes,
  userVotedOnEvent,
  setAvailableVotesLoading,
  setEventAvailableVotes,
  setBonusNotifications,
  setNotificationRead,
} = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth && state.auth.user;

export const selectCurrentUserLoading = (state) => state.auth.loading;

export const selectAvailableVotesLoading = (state) => state.auth.availableVotesLoading;

export const selectCurrentUserId = (state) => state.auth && state.auth.user && state.auth.user.id;
export const selectUserLeaderboardVotes = (state) =>
  state.auth && state.auth.user && state.auth.user.leaderboardAvailableVotes;

export const selectUserEventVotes = (state) =>
  state.auth && state.auth.user && state.auth.user.eventAvailableVotes;

export const selectUserLocalEventVotes = (state) =>
  state.auth && state.auth.user && state.auth.user.localEventAvailableVotes;

export const selectBonusNotifications = (state) => state.auth && state.auth.bonusNotifications;

export const signUpWithGoogle = () => async (dispatch) => {
  try {
    dispatch(signInStart());
    const user = await handleGoogleSignUp();
    dispatch(signInSuccess({ user }));
    dispatch(toggleModalVisibility({ isOpen: false }));
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
};

export const signUpWithFacebook = () => async (dispatch) => {
  try {
    dispatch(signInStart());
    const user = await handleFacebookSignUp();
    dispatch(signInSuccess({ user }));
    dispatch(toggleModalVisibility({ isOpen: false }));
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
};

export const signUpWithApple = () => async (dispatch) => {
  try {
    dispatch(signInStart());
    const user = await handleAppleSignUp();
    dispatch(signInSuccess({ user }));
    dispatch(toggleModalVisibility({ isOpen: false }));
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
};

export const loadUserProfile = () => async (dispatch) => {
  try {
    dispatch(signInStart());
    const user = await getUserCurrentUser();
    dispatch(signInSuccess({ user }));
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
};

export const signOutUser = () => async (dispatch) => {
  try {
    dispatch(logOffStart());
    await signOut();
    dispatch(logOff());
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
};

export const startMonitoringUserEventAvailabeVotes = (currentUserId) => async (dispatch) => {
  try {
    dispatch(setAvailableVotesLoading());
    firestoreDb
      .collection('availableVotes')
      .doc(currentUserId)
      .onSnapshot((querySnapshot) => {
        const availableVotes = querySnapshot.data();
        dispatch(setEventAvailableVotes({ availableVotes }));
      });
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
};

export const startMonitoringUserLeaderboardAvailabeVotes = (currentUserId) => async (dispatch) => {
  try {
    dispatch(setAvailableVotesLoading());
    firestoreDb
      .collection('leaderboardUserAvailableVotes')
      .doc(currentUserId)
      .onSnapshot((querySnapshot) => {
        const availableVotes = querySnapshot.data();
        dispatch(setLeaderboardAvailableVotes({ availableVotes }));
      });
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  };

}

export const startListeningToBonusNotifications = (currentUserId) => async (dispatch) => {
  try {
    firestoreDb
      .collection('userPushNotificationHistory')
      .doc(currentUserId)
      .collection('notifications')
      .where('read', '==', false)
      .where('data.navigation', '==', 'bonusRewardAchieved')
      .onSnapshot((querySnapshot) => {
        const BonusNotifications = querySnapshot.docs.map(doc => {
          const data = doc.data()
          data.id = doc.id
          delete data.timestamp
          return data
        });
        dispatch(setBonusNotifications({ BonusNotifications }));
      });
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
}

export const readNotification = (notificationId) => async (dispatch) => {
  try {
    const bonus = await markBonusAsRead(notificationId)
    return
  } catch (err) {
    console.error(err);
    dispatch(signInFailure(err.toString()));
  }
}
