import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { selectCitiesCurrentArtistIndexes, selectCurrentCitySession } from '../liveSessionSlice';
import { useSelector } from 'react-redux';
import { selectSessionVendors } from '../liveSessionSlice';

const useStyles = makeStyles(() => ({
  contestantListContainer: {
    marginLeft: '15px',
  },
  contestantListContainerRelative: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    pointerEvents: "none",
  },
  dialContainer: {
    height: '65px',
  },
  dialImage: {
    height: '50px',
    width: '50px',
    position: 'absolute',
    top: '-2px',
    left: '2px',
  },
  dialImageCenter: {
    height: '50px',
    width: '50px',
    position: 'absolute',
    top: '-2px',
    left: '2px',
  },
  dialImageCircle: {
    width: '53px',
    height: '51px',
    borderRadius: '50%',
    position: 'relative',
    backgroundColor: 'white',
  },
  dialImageCircleCenter: {
    marginLeft: '15px',
    width: '53px',
    height: '51px',
    borderRadius: '50%',
    position: 'relative',
    backgroundColor: 'white',
  },
  relative: {
    position: 'relative',
    opacity: 0.5,
  },
  relativeCenter: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bolder',
  },
}));

const ContestantImage = ({ currentSlideIndex, contestant, contestantIndex }) => {
  const classes = useStyles();
  return (
    <div className={classes.dialContainer} key={contestantIndex}>
      {currentSlideIndex === contestantIndex ? (
        <div className={classes.relativeCenter}>
          <div className={classes.dialImageCircleCenter}>
            <img
              className={classes.dialImageCenter}
              src={contestant.profileAssets?.dialUrl}
              alt=""
            />
          </div>
          <label style={{ marginLeft: 10 }}> {contestant.artistName} </label>
        </div>
      ) : (
        <div className={classes.relative}>
          <div className={classes.dialImageCircle}>
            <img className={classes.dialImage} src={contestant.profileAssets?.dialUrl} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export const CitySessionContestantList = ({ contestants, currentSlideIndex }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.contestantListContainerRelative} contestantListContainerRelative`}>
      <div className={classes.contestantListContainer}>
        {(contestants || []).map((contestant, index) => (
          <ContestantImage
            key={index}
            contestant={contestant}
            currentSlideIndex={currentSlideIndex}
            contestantIndex={index}
          />
        ))}
      </div>
    </div>
  );
};

export const FloatingCitySessionContestantList = function () {
  const vendors = useSelector(selectSessionVendors) || [];
  const currentCitySession = useSelector(selectCurrentCitySession);
  const citiesCurrentArtistIndexes = useSelector(selectCitiesCurrentArtistIndexes);
  const currentSlideIndex =
    (citiesCurrentArtistIndexes &&
      currentCitySession &&
      citiesCurrentArtistIndexes[currentCitySession.id].index) ||
    0;
  const contestantListWithVendors = currentCitySession.contestants?.concat(vendors) || []

  function calculateIfShowImage(currensSlideIndex, index2, arrayLength) {
    if (arrayLength <8) {
      return true
    }
    if (currensSlideIndex == 0 || currensSlideIndex == arrayLength -1) {
      const rightDistance = Math.abs(currensSlideIndex - index2)
      return rightDistance <7
    }
    if (currensSlideIndex == 1 || currensSlideIndex == arrayLength -2) {
      const rightDistance = Math.abs(currensSlideIndex - index2)
      return rightDistance < 6
    }
    if (currensSlideIndex == 2 || currensSlideIndex == arrayLength -3) {
      const rightDistance = Math.abs(currensSlideIndex - index2)
      return rightDistance < 5
    } else  {
      const rightDistance = Math.abs(currensSlideIndex - index2)
      return rightDistance < 4
    }


  }



  const classes = useStyles();
  return currentCitySession ? (
    <div className={`${classes.contestantListContainerRelative}`}>
      <div className={classes.contestantListContainer}>
        {contestantListWithVendors.map((contestant, index) => (
          calculateIfShowImage(currentSlideIndex, index, contestantListWithVendors.length) &&
          <ContestantImage
            key={index}
            contestant={contestant}
            currentSlideIndex={currentSlideIndex}
            contestantIndex={index}
          />
        ))}
      </div>
    </div>
  ) : null;
};
