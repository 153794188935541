import React, { useEffect } from 'react';
import { ArtistsGrid } from '../../artistsTab/components/ArtistsGrid';
import styles from '../LiveSession.module.css';
import { getCitySession } from '../liveSessionSlice';
import { useDispatch } from 'react-redux';
import { Countdown } from './Countdown';
import { useParams } from 'react-router-dom';
import { setModalVisibility } from './../notification/EndOfStreamSlice';

export function PendingToStartGrid({ contestants, citySessionId }) {
  const dispatch = useDispatch();
  const { cityId, artistId } = useParams();

  useEffect(() => {
    if (contestants.length === 0) {
      dispatch(getCitySession({ cityId: citySessionId }));
    }
    if (cityId || artistId) {
      dispatch(setModalVisibility({ isOpenModal: true }));
    }
  }, [contestants, dispatch]);

  return (
    <div className={styles.pendingToStartGrid}>
      <Countdown />
      <ArtistsGrid artists={contestants} />
    </div>
  );
}
