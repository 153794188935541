import React from 'react';
import { selectSession } from '../liveSessionSlice';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'rgba(255, 255, 255, .07)',
    width: '90%',
    height: '114px',
    margin: 'auto',
    borderRadius: '10px',
  },
  topTitle: {
    margin: 'auto',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'block',
    paddingTop: '8pt',
  },
  timerContainer: {
    width: '200px',
    height: '80px',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    color: 'white',
  },
  timerItemContainer: {
    width: '33%',
    height: '100%',
    paddingTop: '10px',
  },
  timerCircle: {
    width: '46px',
    height: '46px',
    borderRadius: '50%',
    border: '2px solid #D81F37',
    margin: 'auto',
    position: 'relative',
  },
  timerLabel: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 14,
  },
  timerValue: {
    width: '50%',
    height: '50%',
    overflow: 'auto',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
}));

export function Countdown() {
  const classes = useStyles();

  const session = useSelector(selectSession) || {};

  let message = '';
  let now = moment();
  let dateToUse = moment();
  switch (session.status) {
    case 'live':
      message = 'Session ends in';
      dateToUse = moment(session.duration.endDate);
      break;
    case 'ended':
      message = 'Session ended';
      dateToUse = moment(session.duration.endDate);
      break;
    case 'pendingToStart':
      message = 'NEXT SESSION STARTS IN';
      dateToUse = moment(session.duration.startDate);
      break;
    default:
  }

  const diff = dateToUse.diff(now);
  const diffDuration = moment.duration(diff);
  const days = diffDuration.days();
  const hours = diffDuration.hours();
  const minutes = diffDuration.minutes();

  return session && session.duration ? (
    <div className={classes.container}>
      <label className={classes.topTitle}> {message}</label>
      <div className={classes.timerContainer}>
        <TimerItem label="Days" value={days} />
        <TimerItem label="hours" value={hours} />
        <TimerItem label="Mins" value={minutes} />
      </div>
    </div>
  ) : null;
}

const TimerItem = ({ label, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.timerItemContainer}>
      <div className={classes.timerCircle}>
        <label className={classes.timerValue}>{value}</label>
      </div>
      <label className={classes.timerLabel}>{label}</label>
    </div>
  );
};
