import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export const LoadingIndicator = () => {
  return (
    <div
      style={{
        width: '100%',
        position: 'absolute',
        height: '100%',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
};
