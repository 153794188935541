import { createSlice } from '@reduxjs/toolkit';
import { getArtistProfile } from '../../api/artistProfile.api';

const initialState = {
  profile: null,
  loading: false,
  error: null,
};

function startLoading(state) {
  state.loading = true;
}

function loadingFailed(state, action) {
  state.loading = false;
  state.error = action.payload;
}

export const artistProfileSlice = createSlice({
  name: 'artistProfile',
  initialState,
  reducers: {
    artistProfileLoading: startLoading,
    artistProfileSuccess: (state, { payload }) => {
      const { profile } = payload;

      state.profile = profile;
      state.loading = false;
      state.error = null;
    },
    leaderboardArtistProfileVoted: (state) => {
      const profile = { ...state.profile };
      profile.totalLeaderboardVotes++;
      state.profile = profile;
      state.loading = false;
      state.error = null;
    },
    eventArtistProfileVoted: (state) => {
      const profile = { ...state.profile };
      profile.totalHistoricalVotes++;
      state.profile = profile;
      state.loading = false;
      state.error = null;
    },
    artistProfileFailure: loadingFailed,
  },
});

export const {
  artistProfileLoading,
  artistProfileSuccess,
  artistProfileFailure,
  leaderboardArtistProfileVoted,
  eventArtistProfileVoted,
} = artistProfileSlice.actions;

export default artistProfileSlice.reducer;

export const selectProfile = (state) => state.artistProfile && state.artistProfile.profile;

export const selectArtistProfileLoading = (state) => state.artistProfile.loading;

//Thunk
export const getProfile = (userId) => async (dispatch) => {
  try {
    dispatch(artistProfileLoading());
    const profile = await getArtistProfile(userId);
    dispatch(artistProfileSuccess({ profile }));
  } catch (err) {
    dispatch(artistProfileFailure(err.toString()));
  }
};
