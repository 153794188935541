import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCitySessions } from '../../features/liveSession/liveSessionSlice';

const useValidateCity = (city) => {
  const cities = useSelector(selectCitySessions);
  const history = useHistory();

  if (cities && cities.length !== 0 && !cities.map((c) => c.id).includes(city)) {
    history.push('/detroit');
  }
};

export default useValidateCity;
