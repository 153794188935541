import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import './ButtonStyles.css';
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../../api/ecommerce.api';
import './ButtonStyles.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { platformSet } from './ecommerceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { loadProductByIntent, selectProduct, selectProductLoading, selectError } from './singleProductSlice';
import { CircularProgress } from '@material-ui/core';
import ConfirmationPage from './confirmationPage';

export default function CheckoutLastStep() {
  const clientSecret = new URLSearchParams(window.location.search).get(
    'payment_intent_client_secret',
  );
  const options = {
    clientSecret,
  };

  return (
    clientSecret && (
      <Elements options={options} stripe={stripePromise}>
        <LastStep clientSecret={clientSecret} />
      </Elements>
    )
  );
}

const LastStep = ({ clientSecret }) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { platform } = useParams();
  const [message, setMessage] = useState(null);
  const product = useSelector(selectProduct);
  const productLoading = useSelector(selectProductLoading);
  const error = useSelector(selectError);
  const [intentId, setItentId] = useState('');


  
  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          dispatch(loadProductByIntent(paymentIntent.id))
          setItentId(paymentIntent.id)
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleClick = () => {
    if (platform === 'ios' || platform === 'android') {
      window.location.href = 'headlights://purchaseSuccess';
    } else {
      window.location.href = `${window.location.origin}/ecommerce`;
    }
  };

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (platform) {
      dispatch(platformSet(platform));
    }
  }, [platform]);

  if (productLoading || error) {
    return <CircularProgress></CircularProgress>
  }


  return (
    clientSecret && (
      <div style={{ overflowY: 'auto', height: '90%', padding: '16px' }}>
        {/* Show any error or success messages */}
        {message && message !== 'Payment succeeded!' && (
          <div style={{ color: 'white', textAlign: 'center' }} id="payment-message">
            {message}
          </div>
        )}
        {message === 'Payment succeeded!' && (  //here goes extra info
          <ConfirmationPage intentId={intentId} product={product}></ConfirmationPage>
        )}
        {message && message !== 'Payment succeeded!' && (<button className="stripe-pay-button" onClick={handleClick}>
          <span id="button-text">{'Return'}</span>
        </button>)}
      </div>
    )
  );
};
