import React, { useEffect, useState } from 'react';
import styles from './VoteButton.module.css';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCurrentUser,
  selectCurrentUserLoading,
  selectAvailableVotesLoading,
  selectUserEventVotes,
  selectUserLocalEventVotes,
} from '../auth/authSlice';
import { eventArtistProfileVoted } from '../artistProfile/artistProfileSlice';
import { toggleModalVisibility } from '../loginModal/loginModalSlice';
import {
  castLiveSessionVote,
  selectLiveSessionRealId,
  selectArtistVotesOnSlid,
} from '../liveSession/liveSessionSlice';

function EventVoteButton({ contestantId, cityId }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const isCurrentUserLoading = useSelector(selectCurrentUserLoading);
  const isAvailableVotesLoading = useSelector(selectAvailableVotesLoading);
  const competitionId = useSelector(selectLiveSessionRealId);
  const [currentCompetitionId, setCurrentCompetitionId] = useState();
  const videoInitialVotes = useSelector(selectArtistVotesOnSlid);
  const [VideoVotes, setVideoVotes] = useState(videoInitialVotes); //idk why if not put that 0 the state is null

  useEffect(() => {
    // Actualizar el estado cuando videoInitialVotes cambie
    setVideoVotes(videoInitialVotes);
  }, [videoInitialVotes]);

  useEffect(() => {
    setCurrentCompetitionId(competitionId);
  }, [competitionId]);

  const availableVotes = useSelector(selectUserEventVotes);
  // I will use this state to update the votes in the UI without waiting for the API response
  const availableLocalVotes = useSelector(selectUserLocalEventVotes);

  const onVoting = (event) => {
    event.stopPropagation();
    if (isCurrentUserLoading || isAvailableVotesLoading) {
      return;
    }

    if (currentUser && currentUser.id && availableVotes) {
      if (availableLocalVotes === 0) {
        console.warn('Out of votes');
        return;
      }
      const voteData = {
        competitionId: currentCompetitionId,
        contestantId,
        userId: currentUser.id,
        cityId,
      };
      dispatch(castLiveSessionVote(voteData));
      dispatch(eventArtistProfileVoted());
      setVideoVotes(VideoVotes + 1);
    } else {
      dispatch(
        toggleModalVisibility({ isOpen: true, message: 'You need to sign in order to vote!' }),
      );
    }
  };
  const voteButtonFontSize = availableLocalVotes ? (24 - availableLocalVotes.toString().length*2 -1) + 'px' : 0
  const voteButtonPaddingTop = availableLocalVotes ? availableLocalVotes.toString().length +1 : 0 

  return (
    <div>
      <div
        style={{
          marginTop: '-160px',
          marginBottom: '140px',
          pointerEvents: 'none',
          position: 'absolute',
          marginLeft: '20px',
        }}
      >
        {VideoVotes}
      </div>
      <div
        style={{ opacity: isAvailableVotesLoading ? 0.7 : 1 }}
        className={styles.voteButtonRoot}
        onClick={(e) => onVoting(e)}
      >
        <div className={styles.voteRing} />
        <div
          style={{
            background:
              availableLocalVotes && availableLocalVotes !== 0
                ? 'linear-gradient(#EE9742, #D81F37)'
                : '#6f6f6f',
          }}
          className={styles.eventVoteButtonContainer}
        >
          {availableLocalVotes && availableLocalVotes !== 0 ? (
            <div style={{fontSize: voteButtonFontSize, paddingTop: voteButtonPaddingTop}} className={styles.eventVoteButton}>+{availableLocalVotes}</div>
          ) : (
            <div className={styles.outOfVotesButton}>
              Get more <br />
              <span>votes</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EventVoteButton;
