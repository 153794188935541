import { createSlice } from '@reduxjs/toolkit';
import { getProduct, getProductByIntent } from '../../api/ecommerce.api';

const initialState = {
  isLoading: false,
  product: {},
  productLoaded: false,
  error: false,
};

export const singleProductSlice = createSlice({
  name: 'singleProduct',
  initialState,
  reducers: {
    loadingStarted: (state) => {
      state.isLoading = true;
    },
    loadSucceded: (state) => {
      state.isLoading = false;
    },

    productLoaded: (state, { payload }) => {
      state.product = payload.product;
      state.isLoading = false;
      state.productLoaded = true;
      state.error = false
    },
    errorLoading: (state) => {
      state.error = true;
      state.isLoading = false;
    },
  },
});

export const { loadingStarted, productLoaded, errorLoading } = singleProductSlice.actions;

export default singleProductSlice.reducer;

export const selectProductLoading = (state) => state && state.singleProduct.isLoading;
export const selectProduct = (state) => state && state.singleProduct.product;
export const selectError = (state) => state && state.singleProduct.error;
export const loadProduct = (productId) => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    const product = await getProduct(productId);
    dispatch(productLoaded({ product }));
  } catch (err) {
    dispatch(errorLoading())
    console.error(err);
  }
};

export const loadProductByIntent = (intentId) => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    const product = await getProductByIntent(intentId);
    dispatch(productLoaded({ product }));
  } catch (err) {
    dispatch(errorLoading())
    console.error(err);
  }
};