import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FanProfile.module.css';
import { selectCurrentUser, loadUserProfile } from '../auth/authSlice';
import { selectArtistRequestSent } from '../artistRequest/artistRequestSlice';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CreateIcon from '@material-ui/icons/Create';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { upperCaseName } from '../../utils/common';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';

export const LocationIndicator = ({ cityName }) => {
  return (
    <div className={styles.cityLabel}>
      {' '}
      <LocationOnIcon className={styles.cityIcon} />{' '}
      <label className={styles.cityNameLabel}> {cityName ?? '----'} </label>{' '}
    </div>
  );
};

export function FanProfile() {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const history = useHistory();
  const artistRequestSent = useSelector(selectArtistRequestSent);

  const artistRequestTapped = () => history.push('/profile/artistrequest');

  useEffect(() => {
    if (artistRequestSent && currentUser && currentUser.applicationStatus !== 'pending') {
      dispatch(loadUserProfile());
    }
  }, []);

  return currentUser ? (
    <div className={styles.fanProfileContainer}>
      <IconButton
        onClick={() => history.push('/profile/options')}
        className={styles.elipsisIcon}
        color="primary"
        aria-label="upload picture"
        component="span"
      >
        <MoreVertIcon />
      </IconButton>

      <div className={styles.itemsContainer}>
        <img className={styles.fanProfileImage} src={currentUser.photoUrl} alt="" />
        <div className={styles.fanInfoContainer}>
          <div className={styles.fanLabel}>
            {' '}
            <label> Fan </label>
          </div>
          <div className={styles.fanName}>
            {' '}
            {upperCaseName(currentUser.name) + upperCaseName(currentUser.lastName)}
          </div>
          <LocationIndicator cityName={currentUser.city} />
        </div>
      </div>
      <div className={styles.statisticsContainer}>
        <div className={styles.statisticLabel}>
          <label> 0 </label>
          <label className={styles.statisticLabelBottom}> Voted </label>
        </div>
        <div className={styles.statisticLabel}>
          <label> 0 </label>
          <label className={styles.statisticLabelBottom}> Shares </label>
        </div>
      </div>
      <div className={styles.bottomButtons}>
        {currentUser.applicationStatus === 'pending' ? (
          <button
            disabled={true}
            onClick={artistRequestTapped}
            className={styles.sendArtistRequestButtonDisabled}
          >
            <span> ARTIST REQUEST PENDING </span>
          </button>
        ) : (
          <button onClick={artistRequestTapped} className={styles.sendArtistRequestButton}>
            <span> SEND REQUEST TO BE AN ARTIST </span>
          </button>
        )}

        <button className={styles.editProfileButton}>
          <CreateIcon />
        </button>
      </div>
    </div>
  ) : (
    <div />
  );
}
