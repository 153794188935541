import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ArtistProfile.module.css';
import { Prompt } from 'react-router-dom';
import { Player, ControlBar, BigPlayButton } from 'video-react';
import HLSSource from '../../utils/HLSSource';
import ShareIcon from '../../assets/shareIcon.svg';
import CancelIcon from '@material-ui/icons/Close';
import FullScreenIcon from '@material-ui/icons/ZoomOutMap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import LocationIcon from '@material-ui/icons/LocationOn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import AppleIcon from '@material-ui/icons/Apple';
import SpotifyIcon from '../../assets/spotifyIcon.svg';
import SoundCloudIcon from '../../assets/soundCloudIcon.svg';
import { getProfile, selectProfile, selectArtistProfileLoading } from './artistProfileSlice';
import CircularProgress from '@material-ui/core/CircularProgress';
import Zoom from '@material-ui/core/Zoom';

import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import { selectCitySessions } from '../liveSession/liveSessionSlice';
import { trackUserOpenedHomepageProfile, trackShareProfile } from '../../api/analytics';

export const VideoSideBar = ({ style, artist }) => {
  return (
    <div className={styles.videoSideBar} style={style}>
      <ShareProfileButton artist={artist} />
    </div>
  );
};

const CardBottom = ({ artist }) => {
  return (
    <div className={styles.cardBottom}>
      <div className={styles.gradientWrapper}>
        <img className={styles.profileImage} src={artist.profileAssets && artist.photoUrl} alt="" />
      </div>
      <div className={styles.artistName}>{artist.artistName}</div>
    </div>
  );
};

const FeaturedVideo = ({ artist, isCurrentUser, onCloseTapped }) => {
  const player = React.createRef();
  const [playerCurrent, setPLayerCurrent] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { featuredVideo, cityId } = artist;
  const { streamingUrl, thumbnailUrl } = featuredVideo || {};
  const streamingType = streamingUrl && streamingUrl.includes('m3u8') ? 'hls' : 'mp4';

  const handlePlayerStateChange = (state, prevState) => {
    if (state.isFullscreen !== prevState.isFullscreen) {
      setIsFullScreen(state.isFullscreen);
    }
  };

  useEffect(() => {
    if (player.current && playerCurrent == null) {
      setPLayerCurrent(player.current);
      player.current.subscribeToStateChange(handlePlayerStateChange);
    }
  }, [player, playerCurrent]);

  const handleFullScreenPlayer = () => {
    playerCurrent.video.toggleFullscreen();
  };

  return (
    <div className={styles.containerFullScreen}>
      <div className={styles.closeReturnToCard}>
        <div className={styles.videoSideBarButton}>
          <FullScreenIcon onClick={handleFullScreenPlayer} className={styles.smallMargin} />

          {!isCurrentUser ? <CancelIcon onClick={onCloseTapped} /> : null}
        </div>
      </div>

      <div className={styles.imageContainer}>
        {streamingUrl && streamingType === 'hls' ? (
          <Player
            className={styles.featuredVideo}
            poster={thumbnailUrl}
            autoPlay={true}
            ref={player}
            muted={false}
            playsInline={true}
            loop
          >
            <HLSSource isVideoChild src={streamingUrl} />
            <BigPlayButton position="center" className={styles.bigPlayButton} />
            <ControlBar disableCompletely={!isFullScreen} />
          </Player>
        ) : (
          <div />
        )}

        <CardBottom artist={artist} />
        {isCurrentUser ? null : (
          <VideoSideBar artist={artist} votes={8} contestantId={artist.id} cityId={cityId} />
        )}
      </div>
    </div>
  );
};

export const ShareProfileButton = ({ artistId, cityId }) => {
  const handleShareClick = async (event) => {
    event.stopPropagation();
    if (navigator && navigator.share) {
      trackShareProfile(artistId, cityId);
      await navigator.share({
        title: 'Headlights',
        text: '',
        url: `${location.host}/${artistId}/${cityId}`,
      });
    }
  };
  return (
    <div className={styles.shareIconHeaderContainer}>
      <img
        onClick={handleShareClick}
        className={styles.shareIconHeader}
        src={ShareIcon}
        alt=""
      ></img>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={styles.tabPanel}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

const ProfileSection = ({ children, sectionTitle, hasTopBorder, hasBottomBorder }) => {
  return (
    <div
      className={`${styles.profileSectionHeader} ${hasTopBorder ? styles.topBorderSection : ''}
      ${hasBottomBorder ? styles.bottomBorderSection : ''}`}
    >
      <div className={styles.profileSectionHeaderContainer}>
        <Typography className={styles.aboutText}>{sectionTitle}</Typography>
        {children}
      </div>
    </div>
  );
};

const SocialMediaLink = ({ socialMediaLink }) => {
  const { type, url } = socialMediaLink;

  const handleSocialLinkClick = () => {
    const finalUrl =
      type === 'instagram'
        ? `https://www.instagram.com/${url}`
        : type === 'twitter'
          ? `https://www.twitter.com/${url}`
          : type === 'soundCloud'
            ? `https://www.soundcloud.com/${url}`
            : url;
    window.open(finalUrl, '_blank');
  };

  const icon =
    type === 'instagram' ? (
      <InstagramIcon />
    ) : type === 'spotify' ? (
      <img className={styles.socialMediaLinkIcon} alt="" src={SpotifyIcon}></img>
    ) : type === 'soundCloud' ? (
      <img className={styles.socialMediaLinkIcon} alt="" src={SoundCloudIcon}></img>
    ) : type === 'twitter' ? (
      <TwitterIcon />
    ) : type === 'appleMusic' ? (
      <AppleIcon />
    ) : null;

  return (
    icon && (
      <div className={styles.socialMediaLink} onClick={handleSocialLinkClick}>
        {icon}
      </div>
    )
  );
};

const SocialMedia = ({ socialMediaLinks }) => {
  return (
    <div className={styles.socialMediaContainer}>
      {socialMediaLinks &&
        socialMediaLinks.map((link, index) => (
          <SocialMediaLink key={index} socialMediaLink={link} />
        ))}
    </div>
  );
};

const ProfileTabs = ({ artist }) => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const { city, socialMediaLinks, artistDescription } = artist;

  const handleChange = (event, newIndex) => {
    setActiveTabIndex(newIndex);
  };
  const getTabStyle = (tabIndex) => {
    const isActive = tabIndex === activeTabIndex;
    return isActive ? styles.activeTab : styles.inactiveTab;
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className={styles.profileTabsContainer}>
      <div>
        <Tabs
          className={styles.tabsHeader}
          TabIndicatorProps={{
            style: {
              height: '4px',
            },
          }}
          value={activeTabIndex}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            className={getTabStyle(0)}
            classes={{
              root: styles.tabsRoot,
            }}
            {...a11yProps(0)}
            label="Profile"
          />
          {/* <Tab
            className={getTabStyle(1)}
            classes={{
              root: styles.tabsRoot,
            }}
            {...a11yProps(1)}
            label="Status"
          /> */}
        </Tabs>
      </div>
      <TabPanel value={activeTabIndex} index={0}>
        <ProfileSection sectionTitle="About" hasTopBorder={false} hasBottomBorder={true}>
          <div>
            <Typography className={styles.aboutInfo}>{artistDescription}</Typography>
            <div className={styles.locationCityProfile}>
              <LocationIcon className={styles.locationIcon} />
              <Typography className={styles.cityText}>{city}</Typography>
            </div>
          </div>
        </ProfileSection>
        {/* <ProfileSection
          sectionTitle="YouTube Videos"
          hasTopBorder={false}
          hasBottomBorder={true}
          children={<YouTubeVideos youTubeVideos={youTubeVideos} />}
        /> */}
        <ProfileSection sectionTitle="SocialMedia" hasTopBorder={false} hasBottomBorder={false}>
          <SocialMedia socialMediaLinks={socialMediaLinks} />
        </ProfileSection>
      </TabPanel>
      {/* <TabPanel value={activeTabIndex} index={1}></TabPanel> */}
    </div>
  );
};

function ArtistHero({ profile, onCloseTapped }) {
  const citySessions = useSelector(selectCitySessions);
  const citySession = citySessions && citySessions.find((cs) => cs.id === profile.cityId);
  return (
    <div className={styles.artistCardContainer}>
      <div className={styles.artistCard}>
        <div className={`${styles.imageStackContainer} imageStackContainer`}>
          <img
            className={`${styles.imageStackBackground}`}
            src={citySession && citySession.eventBackgroundUrl}
            alt=""
          />
          <img
            className={`${styles.imageStackCutout}`}
            src={`${profile.profileAssets && profile.profileAssets.cutoutUrl}`}
            alt=""
          />
        </div>

        <CardBottom artist={profile} />
        <div
          style={{
            top: 4,
            right: 4,
            position: 'absolute',
          }}
          className={styles.videoSideBarButton}
        >
          <CancelIcon onClick={onCloseTapped} />
        </div>
      </div>
    </div>
  );
}

export function ProfileContainer({
  fromliveSession,
  isCurrentUser,
  artistId,
  position,
  onCloseTapped,
}) {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);
  const artistProfileLoading = useSelector(selectArtistProfileLoading);
  useEffect(() => {
    if (artistId) {
      dispatch(getProfile(artistId));
      if (fromliveSession) {
        trackUserOpenedHomepageProfile(artistId);
      }
    }
  }, []);

  return profile && !artistProfileLoading ? (
    <div className={styles.leaderProfileContainer}>
      <div className={styles.featuredVideoContainer}>
        {fromliveSession ? (
          <ArtistHero onCloseTapped={onCloseTapped} profile={profile} />
        ) : (
          <FeaturedVideo
            artist={profile}
            position={position}
            isCurrentUser={isCurrentUser}
            onCloseTapped={onCloseTapped}
          />
        )}
        {/* {!isCurrentUser ? <ProfilePointsDropdown artist={profile} style={{ left: 0, top: 18 }} /> : null} */}
      </div>
      <div className={styles.profileBodyContainer}>
        <ProfileTabs artist={profile} />
      </div>
    </div>
  ) : (
    <div className={styles.profileLoading}>
      <CircularProgress color="primary" />
    </div>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

export const ProfileModal = ({
  fromliveSession,
  onClose,
  open,
  isCurrentUser,
  artistId,
  position,
}) => {
  return (
    <Dialog
      classes={{
        paper: styles.dialog,
      }}
      TransitionComponent={Transition}
      onClose={onClose}
      open={open}
      fullScreen={true}
    >
      <DialogContent className={styles.profileModalDialog}>
        <ProfileContainer
          fromliveSession={fromliveSession}
          isCurrentUser={isCurrentUser}
          artistId={artistId}
          position={position}
          onCloseTapped={onClose}
        />
      </DialogContent>
      <Prompt
        message={(location, action) => {
          if (action === 'POP') {
            onClose();
            return false;
            // Add your back logic here
          }

          return true;
        }}
      />
    </Dialog>
  );
};
