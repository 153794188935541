import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  message: '',
};

export const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState,
  reducers: {
    toggleModalVisibility: (state, { payload }) => {
      const { isOpen, message } = payload;
      state.isOpen = isOpen;
      state.message = message;
    },
  },
});

export const { toggleModalVisibility } = loginModalSlice.actions;

export default loginModalSlice.reducer;

export const selectModalVisibility = (state) => state.loginModal.isOpen;
export const modalMessage = (state) => state.loginModal.message;
