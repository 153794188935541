import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

export const redirectModalSlice = createSlice({
  name: 'redirectModal',
  initialState,
  reducers: {
    toggleModalVisibility: (state, { payload }) => {
      const { isOpen } = payload;
      state.isOpen = isOpen;
    },
  },
});

export const { toggleModalVisibility } = redirectModalSlice.actions;

export default redirectModalSlice.reducer;

export const selectModalVisibility = (state) => state.redirectModal.isOpen;
