import React, { useRef, useState, useCallback } from 'react';
import styles from './ImageUploadInput.module.css';
import { CircularProgress, Typography } from '@material-ui/core';
import { uploadPictureToUserBucket } from '../../api/storageProvider';

import CameraAltIcon from '@material-ui/icons/CameraAlt';

export const ImageUploadInput = ({ onChange }) => {
  const [uploadProgressPercentage, setUploadProgressPercentage] = useState(0);
  const [uploadingImage, setUploadingImage] = useState(false);
  const inputFile = useRef(null);
  const [currentImageSrc, setCurrentImageSrc] = useState('');

  const uploadVideoFile = useCallback(
    async (file) => {
      // don't send again while we are sending
      if (uploadingImage) return;
      // update state
      setUploadingImage(true);
      // send the actual request
      uploadPictureToUserBucket(
        file,
        function uploadProgress(progress) {
          setUploadProgressPercentage(parseInt(progress));
        },
        function error(err) {
          console.error(err);
        },
        function completed(storagePath, downloadUrl) {
          if (onChange) {
            onChange(downloadUrl);
          }

          setCurrentImageSrc(downloadUrl);
          setUploadingImage(false);
        },
      );
    },
    [uploadingImage],
  );

  const handleImageSelected = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    uploadVideoFile(file);
  };

  const handleUploadClick = (e) => {
    e.preventDefault();
    inputFile.current.click();
  };

  return (
    <div>
      <div className={styles.container}>
        <img hidden={!currentImageSrc} src={currentImageSrc} className={styles.img} alt="" />
        {uploadingImage ? (
          <div className={styles.progress}>
            <CircularProgress size={20} className={styles.loading} />
            <Typography variant="h5" gutterBottom>
              {uploadProgressPercentage}%
            </Typography>
          </div>
        ) : null}
        {!uploadingImage ? (
          <button className={styles.btn} onClick={(e) => handleUploadClick(e)}>
            {' '}
            <div className={styles.innerButtonContainer}>
              <CameraAltIcon className={styles.uploadIcon} /> <label> Tap to add photo</label>
            </div>{' '}
          </button>
        ) : null}
      </div>
      <input
        style={{ display: 'none' }}
        ref={inputFile}
        type="file"
        id="image"
        name="image"
        accept="image/*"
        onChange={(e) => handleImageSelected(e)}
      ></input>
    </div>
  );
};
