import React from 'react';
import { TopNavigationBar } from '../commonComponents/TopNavigationBar';
import { useDispatch } from 'react-redux';
import { signOutUser } from '../auth/authSlice';
import styles from './ProfileOptions.module.css';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';

export const ProfileOptions = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignout = () => {
    dispatch(signOutUser());
    history.push('/profile/');
  };

  return (
    <div>
      <TopNavigationBar navBarTitle={'Profile Options'} />
      <button className={styles.optionsButton} onClick={handleSignout}>
        <ExitToAppIcon />
        <span> signout</span>
      </button>
    </div>
  );
};
