import { useEffect, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import {
  selectCitiesCurrentArtistIndexes,
  selectCurrentArtistOnSlide,
  selectCurrentCitySession,
  selectSessionVendors,
} from '../../features/liveSession/liveSessionSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useReplaceLink = (isLoading) => {
  const history = useHistory();

  let { cityId, artistId } = useParams();

  const currentArtist = useSelector(selectCurrentArtistOnSlide);
  const currentCity = useSelector(selectCurrentCitySession);
  const vendors = useSelector(selectSessionVendors);
  const currentArtistIndexes = useSelector(selectCitiesCurrentArtistIndexes);
  const firstRender = useRef(true);
  const secondRender = useRef(true);

  useEffect(() => {
    let newPath = '';
    if (isLoading) return;
    if (firstRender.current || secondRender.current) {
      if (currentCity) {
        newPath = `/${cityId}${artistId ? `/${artistId}` : ''}`;
      }

      if (newPath.length > 0) {
        history.replace(newPath);
      }
      if (!firstRender.current) {
        secondRender.current = false;
      }
      firstRender.current = false;
      return;
    }
    const artistOnSlide = currentArtistIndexes[currentCity?.id || 'atlanta'];
    const contestants = currentCity?.contestants ? currentCity.contestants.concat(vendors) : [];
    const contestantId = contestants[artistOnSlide.index]?.id;

    if (currentCity) {
      if (currentCity.isComingSoon) {
        newPath = `/${currentCity && currentCity.id}`;
      } else {
        newPath = `/${currentCity && currentCity.id}${
          !artistOnSlide.showingTop3 ? `/${contestantId}` : ''
        }`;
      }
    }

    if (newPath.length > 0) {
      history.replace(newPath);
    }
  }, [currentArtist, currentCity]);
};

export default useReplaceLink;
