import { createSlice } from '@reduxjs/toolkit';
import { sendArtistRequest } from '../../services/profile.service';
import { createStripePaymentIntent, getProducts, createCheckoutSession } from '../../api/ecommerce.api';

const initialState = {
  isLoading: false,
  products: [],
  purchaseSucceeded: false,
  purchaseFailed: false,
  error: null,
  clientSecret: null,
  clientSecretError: null,
  platform: 'web',
  checkoutSession: null,
  isCheckoutSessionLoading: false
};

export const ecommerceSlice = createSlice({
  name: 'ecommerce',
  initialState,
  reducers: {
    loadingStarted: (state) => {
      state.isLoading = true;
    },
    purchaseSucceeded: (state) => {
      state.purchaseSucceeded = true;
      state.isLoading = false;
    },
    purchaseFailed: (state) => {
      state.purchaseSucceeded = false;
      state.isLoading = false;
      state.purchaseFailed = true;
    },
    productsLoaded: (state, { payload }) => {
      state.products = payload.products;
      state.isLoading = false;
    },
    productsLoadingFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    paymentIntentCreated: (state, { payload }) => {
      state.isLoading = false;
      state.clientSecret = payload.clientSecret;
    },
    paymentIntentCreationFailed: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    platformSet: (state, { payload }) => {
      state.platform = payload;
    },
    checkoutSessionCreated: (state, { payload }) => {
      state.checkoutSession = payload.url;
      state.isCheckoutSessionLoading = false;
    },
    startCheckoutSessionLoading: (state) => {
      state.isCheckoutSessionLoading = true;
    },
  },
});

export const {
  loadingStarted,
  purchaseSucceeded,
  purchaseFailed,
  productsLoaded,
  productsLoadingFailed,
  paymentIntentCreated,
  paymentIntentCreationFailed,
  platformSet,
  startCheckoutSessionLoading,
  checkoutSessionCreated
} = ecommerceSlice.actions;

export default ecommerceSlice.reducer;

export const selectIsCheckoutSessionLoading = (state) => state && state.ecommerce.isLoading;
export const selectCheckoutSession = (state) => state && state.ecommerce.checkoutSession;
export const selectLoading = (state) => state && state.ecommerce.isLoading;
export const selectProducts = (state) => state && state.ecommerce.products;
export const selectProductsLoadingFailed = (state) => state && state.ecommerce.error;
export const selectClientSecret = (state) => state && state.ecommerce.clientSecret;
export const selectClientError = (state) => state && state.ecommerce.clientSecretError;
export const selectPlatform = (state) => state && state.ecommerce.platform;

export const loadProducts = () => async (dispatch) => {
  try {
    dispatch(loadingStarted());
    const products = await getProducts();
    dispatch(productsLoaded({ products }));
  } catch (err) {
    console.error(err);
    dispatch(productsLoadingFailed(err.toString()));
  }
};

export const createPaymentIntent =
  ({ customerId, productId, platform }) =>
  async (dispatch) => {
    try {
      dispatch(loadingStarted());
      const clientSecret = await createStripePaymentIntent(customerId, productId, platform);
      dispatch(paymentIntentCreated({ clientSecret }));
    } catch (err) {
      console.error(err);
      dispatch(paymentIntentCreationFailed(err.toString()));
    }
  };

  export const createStripeCheckoutSession =
  ({ userId, priceId, metadata }) =>
  async (dispatch) => {
    try {
      dispatch(startCheckoutSessionLoading());
      const url = await createCheckoutSession(userId, priceId, metadata);
      dispatch(checkoutSessionCreated({ url }));
    } catch (err) {
      console.error(err);
      dispatch(paymentIntentCreationFailed(err.toString()));
    }
  };
