import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Profile } from './features/auth/Profile';
import './App.css';
import 'swiper/swiper.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
import { AppBottomNavigation } from './app/bottomNavigation/Navigation';
import SubscriptionSuccessPage from './features/ecommerce/SubscriptionFinalStep';
import { ThemeProvider } from '@material-ui/core/styles';
import MainTheme from './app/theme';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUserProfile,
  selectCurrentUserId,
  selectBonusNotifications,
  startMonitoringUserEventAvailabeVotes,
  startMonitoringUserLeaderboardAvailabeVotes,
  startListeningToBonusNotifications,
  setNotificationRead,
  readNotification,
} from './features/auth/authSlice';
import { ProfileOptions } from './features/profileOptions/ProfileOptions';
import { ArtistRequest } from './features/artistRequest/ArtistRequest';
import { LeaderProfile } from './features/artistProfile/LeaderProfile';
import { OpenNativeModal } from './features/openNativeModal/OpenNativeModal';
import { LiveSession } from './features/liveSession/LiveSession';
import { LoginModal } from './features/loginModal/LoginModal';
import { Ecommerce } from './features/ecommerce/Ecommerce';
import ProductsList from './features/ecommerce/productList';
import CheckoutLastStep from './features/ecommerce/checkoutLastStep';
import SingleProductShow from './features/ecommerce/SingleProductShow.js';
import { NotificationModal } from './features/auth/notificationModal';

function App() {
  //setup user session
  const $body = document.querySelector('body');
  let scrollPosition = 0;
  scrollPosition = window.pageYOffset;
  $body.style.overflow = 'hidden';
  $body.style.position = 'fixed';
  $body.style.top = `-${scrollPosition}px`;
  // $body.style.width = "100%";
  const dispatch = useDispatch();
  const currentUserId = useSelector(selectCurrentUserId);
  const notifications = useSelector(selectBonusNotifications)

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(loadUserProfile(user.uid));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (currentUserId) {
      dispatch(startMonitoringUserLeaderboardAvailabeVotes(currentUserId));
      dispatch(startMonitoringUserEventAvailabeVotes(currentUserId));
    }
  });

  useEffect(() => {
    if (currentUserId) {
      dispatch(startListeningToBonusNotifications(currentUserId));
    }
  }, [currentUserId]); 

  const closeNotificationModal = (notificationId) => {
    dispatch(setNotificationRead())
    dispatch(readNotification(notificationId))
  }

  return (
    <Router>
      <ThemeProvider theme={MainTheme}>
        <div className="app">
          
          <Switch>
            <Route exact path="/">
              <LiveSession />
            </Route>
            <Route exact path="/subscription/:productId">
              <SubscriptionSuccessPage />
            </Route>
            <Route exact path="/ecommerce">
              <ProductsList />
            </Route>
            <Route exact path="/ecommerce/product/:productId">
              <SingleProductShow />
            </Route>
            <Route exact path="/ecommerce/checkoutLastStep">
              <CheckoutLastStep />
            </Route>
            <Route exact path="/ecommerce/checkoutLastStep/:platform">
              <CheckoutLastStep />
            </Route>
            <Route exact path="/ecommerce/:userId">
              <ProductsList />
            </Route>
            <Route exact path="/ecommerce/:userId/:productId">
              <Ecommerce />
            </Route>
            <Route exact path="/ecommerce/:userId/:productId/:platform">
              <Ecommerce />
            </Route>
            <Route exact path="/profile/options">
              <ProfileOptions />
            </Route>
            <Route exact path="/profile/artistrequest">
              <ArtistRequest />
            </Route>
            <Route exact path="/profile">
              <Profile />
            </Route>
            <Route exact path="/session">
              <LiveSession />
            </Route>
            <Route path="/:cityId/:artistId">
              <LiveSession />
            </Route>
            <Route path="/:cityId">
              <LiveSession />
            </Route>
            <Route path="/leaderProfile">
              <LeaderProfile />
            </Route>
          </Switch>
          <Switch>
            {notifications?.length > 0 && <NotificationModal notification={notifications[0]} isOpen={true} onClose={() => closeNotificationModal(notifications[0].id)} ></NotificationModal> }
          </Switch>
          <LoginModal />
        </div>
        <AppBottomNavigation />

        <OpenNativeModal />
      </ThemeProvider>
    </Router>
  );
}

export default App;
