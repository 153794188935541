import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/messaging';

import firebaseConfig from '../envConfig';

firebase.initializeApp(firebaseConfig);

//enable analytics
export const analytics = firebase.analytics();

export const firestoreDb = firebase.firestore();
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');

      // Add this code after the requestPermission promise resolution
      messaging
        .getToken({
          vapidKey:
            'BFriCCIczVSM8tTh9_ndLOOStAtLIQqXI879QRfnZ2CbALi9pDx-3rp_sDoEusriSEwdKiLjRMZgIjA0XakXLyw',
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log('Current token for client: ', currentToken);
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log('No Instance ID token available. Request permission to generate one.');
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
}

export default firebase;
