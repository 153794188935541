import React from 'react';
import styles from './TextBox.module.css';

export const TextBox = ({ labelText, placeholder, isMandatory, onChange }) => {
  return (
    <div className={styles.textboxInput}>
      <label>
        {' '}
        {labelText}
        {isMandatory ? '*' : ''}
      </label>
      <input
        type="text"
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
};
