import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSliderMoving,
  selectCurrentArtistOnSlide,
  selectTop3StatusForCurrentCity,
  selectSession,
  selectCurrentCitySessionIndex,
  currenArtistOnSlideChange,
  selectCitySessions,

} from '../liveSessionSlice';
import { ContestantVideo, VideoSideBar } from './ContestantVideo';
import { FloatingCitySessionContestantList } from './CitySessionContestantList';
import classes from './ContestantVideo.module.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const VideoContainer = (props) => {
  const dispatch = useDispatch()
  const isSliderMoving = useSelector(selectSliderMoving);
  const currentArtist = useSelector(selectCurrentArtistOnSlide);
  const currentCitySessionStatus = useSelector(selectTop3StatusForCurrentCity);
  const session = useSelector(selectSession)
  const currentCitySessionIndex = useSelector(selectCurrentCitySessionIndex)
  const citySessions = useSelector(selectCitySessions)
  const { artistId } = useParams()

  if (!citySessions) {
    return null
  }

  useEffect(() => {
    if ( currentCitySessionIndex && !currentArtist && session.citySessions[currentCitySessionIndex].contestants) {
      if (artistId) {
        const artistIndex = session.citySessions[currentCitySessionIndex].contestants.findIndex(c => c.id === artistId)
        dispatch(currenArtistOnSlideChange(session.citySessions[currentCitySessionIndex].contestants[artistIndex]))
      } else {
        dispatch(currenArtistOnSlideChange(session.citySessions[currentCitySessionIndex].contestants[0]))
      }
    }
  }, [artistId]); 

  return (
    <>
    <div style={{pointerEvents: 'none'}}
      hidden={isSliderMoving || !currentArtist || currentCitySessionStatus.showingTop3}
      {...props}
    >
      <ContestantVideo contestant={currentArtist} />
      <div className={classes.containerVotesSidebar}>
        <VideoSideBar
          contestantId={currentArtist ? currentArtist.id : ""}
          cityId={currentArtist ? currentArtist.cityId : ""}
        />
      </div>
    </div>
      <div hidden={!currentArtist || currentCitySessionStatus.showingTop3 }
          style={{
            height: '100vh',
            position: 'absolute',
            top: 0,
            zIndex: 1,
            pointerEvents: 'none',
          }}
        >
          <FloatingCitySessionContestantList />
      </div>
    </>
  );
};
