import { analytics } from './firebaseConfig';
// eslint-disable-next-line no-unused-vars
export const setAnalyticsForUser = (userId) => {
  // firebase.analytics().setUserId(userId)
};

// eslint-disable-next-line no-unused-vars
export const setUserTypeProperty = (userType) => {
  // firebase.analytics().setUserProperties({"userType": userType})
};

export const trackUserVote = (user_id, artist_id, competitionId, cityId) => {
  const timestamp = new Date();
  analytics.logEvent('voting', [user_id, artist_id, competitionId, cityId, timestamp]);
};

export const trackUserOpenedHomepageProfile = (artist_id) => {
  const timestamp = new Date();
  analytics.logEvent('user_opened_homepage_profile', [artist_id, timestamp]);
};

export const trackShareProfile = (artist_id, city_id) => {
  const timestamp = new Date();
  analytics.logEvent('sharing', [artist_id, city_id, timestamp]);
};

export const trackUserViewItem = (productId, productName) => {
  analytics.logEvent('view_item', {
    item_id: productId,
    item_name: productName,
    content_type: 'product',
  });
};

export const trackUserTappedOnProduct = (productId, productName, price) => {
  analytics.logEvent('add_to_cart', {
    item_id: productId,
    item_name: productName,
    quantity: 1, // Quantity of items added
    price: price, // Price of the item
    currency: 'USD',
  });
};

export const trackUserBeginCheckout = (productId, price) => {
  analytics.logEvent('begin_checkout', {
    item_list: [productId],
    item_count: 1,
  });
};

export const trackUserPurchase = (productId, productName, price, intentId) => {
  analytics.logEvent('purchase', {
    transaction_id: intentId,
    value: price, // Total revenue from the purchase
    currency: 'USD',
    items: [
      {
        item_id: productId,
        item_name: productName,
        item_category: 'your_product_category1',
        quantity: 1,
        price: price,
      },
    ],
  });
};
