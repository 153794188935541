import { getRequest } from './authHttpClient';
import { config } from '../utils/apiConfig';
const artistsUrl = `${config.API_URL}/artists`;

export const getArtistsByCity = async (cityId) => {
  const result = await getRequest(
    `${artistsUrl}?cityId=${cityId}&includeFeatured=false&includeWinners=false&limit=100&orderBy=artistName&sortBy=asc`,
  );
  return result.data;
};
