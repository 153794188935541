import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

export const uploadVideo = (file, uploadProgress, error, completed) => {
  if (file) {
    const userId = firebase.auth().currentUser.uid;
    const fileName = `${uuidv4() + file.name}`;
    const refAddress = `${userId}/videos/${fileName}`;
    const storageRef = firebase.storage().ref(refAddress);
    const task = storageRef.put(file);
    task.on(
      'state_changed',
      function progress(snapshot) {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        uploadProgress(percentage);
      },
      error,
      function completedUpload() {
        storageRef.getDownloadURL().then((downloadUrl) => {
          completed(refAddress, downloadUrl, fileName);
        });
      },
    );
  }
};

export const uploadPictureToUserBucket = (file, uploadProgress, error, completed) => {
  if (file) {
    const userId = firebase.auth().currentUser.uid;
    if (!userId) {
      throw new Error('user needs to signin in order to upload to its own bucket');
    }
    const fileName = `${uuidv4() + file.name}`;
    const refAddress = `users/${userId}/images/${fileName}`;
    const storageRef = firebase.storage().ref(refAddress);
    const task = storageRef.put(file);
    task.on(
      'state_changed',
      function progress(snapshot) {
        const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        uploadProgress(percentage);
      },
      error,
      function completedUpload() {
        storageRef.getDownloadURL().then((downloadUrl) => {
          completed(refAddress, downloadUrl, fileName);
        });
      },
    );
  }
};
