const devConfig = {
  apiKey: 'AIzaSyC-O5Ht3pfcpndq-Nn755EWJC8F_hYPIAM',
  serverToken:
    'AAAA5q-T-1M:APA91bGw4CAZqjt9FEuaoCtxvr7TSRH_3NU0RexFuL5e9g1R5AFZy2f3jr1wxLI1eIfSxukBn9vXMV58ZolOnMXIY-LZPyNXvHzERGO3TxD9hO59fRsQinMsBoV3evI259jnOhUpXfL5',
  authDomain: 'revvstreet-ccf65.firebaseapp.com',
  databaseURL: 'https://revvstreet-ccf65.firebaseio.com',
  projectId: 'revvstreet-ccf65',
  storageBucket: 'revvstreet-ccf65.appspot.com',
  messagingSenderId: '990788189011',
  appId: '1:990788189011:web:ffbf1543d07a4f8285ffa6',
  measurementId: 'G-YLZ17ZNV2Q',
  apiDynamicLink: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks',
  apiPushNotification: 'https://fcm.googleapis.com/fcm/send',
  domainUriPrefix: 'https://revvtest.page.link',
  iosBundleId: 'com.revvstreet.revvstreet.dev',
  urlWebApp: 'https://www.thelynxmusicleague.com',
  iosAppStoreId: '1526222689',
  socialTitle: 'Join Headlights dev',
  socialDescription: 'Get onboard, loser!',
  socialImageLink:
    'https://firebasestorage.googleapis.com/v0/b/reevstreetstaging.appspot.com/o/mYu2EAcvxKfpuzuj8OkTI0RswDH2%2Fimages%2F7667309f-89b8-4291-b317-097bd7e06011headlights.png?alt=media&token=49f8dada-7371-4f69-8fd4-ac89128ee88b',
  stripePublishableKey:
    'pk_test_51OV3VXG4GwjK7FAjmtlv40qc6AsIj7gRxD6q1UwAw6E1T5wl3unnunyPZNKXh9qyijYRmDReOHCr3Pi59aN7LJZF00ZDNo8K03',
};

const stgConfig = {
  apiKey: 'AIzaSyA9eJkU6tl8i8K4lDEXfKctIZmjBoWgASU',
  serverToken:
    'AAAAdY5CTqo:APA91bGfOOE2lpCHWXuj5nqx_Q1lXSj3CoPGnoy0WWGa4Lh_hoxM0otF6-17oCnNENeQtjP1acegyUCRNVhEzG2nrS0EDS8vH3j3ICoTsmnrrNZdQ1Q-VKdOEiQdysMdVxBfGm47QFdu',
  authDomain: 'reevstreetstaging.firebaseapp.com',
  databaseURL: 'https://reevstreetstaging.firebaseio.com',
  projectId: 'reevstreetstaging',
  storageBucket: 'reevstreetstaging.appspot.com',
  messagingSenderId: '504897883818',
  appId: '1:504897883818:web:04048b79e4f7ef6c0a8ad3',
  measurementId: 'G-ZD6YTKVC07',
  apiDynamicLink: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks',
  apiPushNotification: 'https://fcm.googleapis.com/fcm/send',
  domainUriPrefix: 'https://stgthelynx.page.link',
  iosBundleId: 'com.revvstreet.revvstreet.staging',
  urlWebApp: 'https://www.thelynxmusicleague.com',
  iosAppStoreId: '1526222689',
  socialTitle: 'Join Headlights Stg',
  socialDescription: 'Get onboard!',
  socialImageLink:
    'https://firebasestorage.googleapis.com/v0/b/reevstreetstaging.appspot.com/o/mYu2EAcvxKfpuzuj8OkTI0RswDH2%2Fimages%2F7667309f-89b8-4291-b317-097bd7e06011headlights.png?alt=media&token=49f8dada-7371-4f69-8fd4-ac89128ee88b',
  stripePublishableKey:
    'pk_test_51OV3VXG4GwjK7FAjmtlv40qc6AsIj7gRxD6q1UwAw6E1T5wl3unnunyPZNKXh9qyijYRmDReOHCr3Pi59aN7LJZF00ZDNo8K03',
};

const prodConfig = {
  apiKey: 'AIzaSyBJQeQCY0u2KtRHIC5D0kaEqBogDW0-DJg',
  authDomain: 'headlightsprod.firebaseapp.com',
  databaseURL: 'https://headlightsprod.firebaseio.com',
  projectId: 'headlightsprod',
  storageBucket: 'headlightsprod.appspot.com',
  messagingSenderId: '930709312720',
  appId: '1:930709312720:web:59c5b89d2486c3379c3ad5',
  measurementId: 'G-F9HPJW5VCR',
  apiDynamicLink: 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks',
  apiPushNotification: 'https://fcm.googleapis.com/fcm/send',
  domainUriPrefix: 'https://headlights.page.link',
  iosBundleId: 'com.revvstreet.headlights.production',
  urlWebApp: 'https://www.headlightshiphop.com',
  iosAppStoreId: '1526222689',
  socialTitle: 'Join Headlights',
  socialDescription: 'Get onboard!',
  socialImageLink:
    'https://firebasestorage.googleapis.com/v0/b/reevstreetstaging.appspot.com/o/mYu2EAcvxKfpuzuj8OkTI0RswDH2%2Fimages%2F7667309f-89b8-4291-b317-097bd7e06011headlights.png?alt=media&token=49f8dada-7371-4f69-8fd4-ac89128ee88b',
  stripePublishableKey:
    'pk_live_51OV3VXG4GwjK7FAjh7RkyNEKHmXPtBZbau7kV46uohr6xtI6CmpBAhX5X7Fs5BQB1upXahYJ5n6ypmcy9ueJm5IC00KzuxwXzq',
};
let config = {};
switch (process.env.REACT_APP_STAGE) {
  case 'dev':
    config = devConfig;
    break;
  case 'devStg':
    config = stgConfig;
    break;
  case 'prodStg':
    config = stgConfig;
    break;
  case 'prod':
    config = prodConfig;
    break;
  case 'devProd':
    config = prodConfig;
    break;
  default:
    config = devConfig;
}

export default config;
