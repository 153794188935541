import { Button, CircularProgress } from '@material-ui/core';
import React, { useState, useEffect, useParams } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadProduct, selectProduct, selectProductLoading } from './singleProductSlice';
import styles from './confirmationPage.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function SubscriptionSuccesPage(productId) {
  const dispatch = useDispatch()
  const isProductLoading = useSelector(selectProductLoading)
  const product = useSelector(selectProduct)
  const history = useHistory()

  const handleClick = () => {
    history.push('/')
  }

  useEffect(() => {
    if (productId) {
      dispatch(loadProduct(productId.productId));
    }
  }, [productId]); 


  return ( isProductLoading ? 
    <CircularProgress></CircularProgress> :
    <div className="subscription-Final-Step">
      <img src='/assets/headlights.png'></img>
      <div className='final-step-box'>
          <h3 className='death-star-title'>{'PAYMENT SUCCESSFUL'}</h3>
          <p className='red-text'>Contact</p>
          <p>purchases@headlightshiphop.com</p>
      </div>
      <div className='final-step-box'>
        <h3 className='red-text'>Purchase Details</h3>
        <div className='details-line'></div>
        <div className='details' style={{display: 'flex', justifyContent: 'space-between'}}>
          <p>{product.name}</p>
          <p>${product.price}</p>
        </div>
      </div>

      <Button onClick={() => handleClick()} className='confirmationButton'>Return</Button>
    </div>
  );
};
