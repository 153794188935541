import { signUpWithGoogle, logOff, signUpWithFacebook, signUpWithApple } from '../api/auth.api';
import { setUserTypeProperty } from '../api/analytics';
import { getCurrentUserProfile, createNewUserProfile } from '../api/userProfile.api';
import { sendRequest } from '../api/artistRequest.api';

export const handleGoogleSignUp = async () => {
  try {
    const user = await signUpWithGoogle();
    let userFromDb = await getCurrentUserProfile();
    if (!userFromDb) {
      userFromDb = await createGoogleUserProfile(user);
    } else {
      setUserTypeProperty(userFromDb);
    }
    return userFromDb;
  } catch (error) {
    console.error('error:', error);
    throw new Error('Something went wrong during the signup process.');
  }
};

export const handleFacebookSignUp = async () => {
  try {
    const user = await signUpWithFacebook();
    let userFromDb = await getCurrentUserProfile();
    if (!userFromDb) {
      userFromDb = await createFacebookUserProfile(user);
    } else {
      setUserTypeProperty(userFromDb);
    }
    return userFromDb;
  } catch (error) {
    console.error(error);
    throw new Error('Something went wrong during the signup process.');
  }
};

export const handleAppleSignUp = async () => {
  try {
    const user = await signUpWithApple();
    let userFromDb = await getCurrentUserProfile();
    if (!userFromDb) {
      userFromDb = await createAppleUserProfile(user);
    } else {
      setUserTypeProperty(userFromDb);
    }
    return userFromDb;
  } catch (error) {
    throw new Error('Something went wrong during the signup process.');
  }
};

export const getUserCurrentUser = async () => {
  return getCurrentUserProfile();
};

export const createGoogleUserProfile = async (userData) => {
  setUserTypeProperty('audience');

  const newUser = {
    id: userData.uid,
    email: userData.email,
    name: userData.displayName,
    photoUrl: userData.photoURL,
    isAnonymous: false,
    userType: 'audience',
    loginProvider: 'google',
  };
  await createNewUserProfile(newUser);
  return newUser;
};

export const createFacebookUserProfile = async (userData) => {
  setUserTypeProperty('audience');
  const newUser = {
    id: userData.uid,
    email: userData.email,
    name: userData.displayName,
    photoUrl: userData.photoURL + `?type=large&access_token=${userData.fbToken}`,
    isAnonymous: false,
    userType: 'audience',
    loginProvider: 'facebook',
  };
  await createNewUserProfile(newUser);
  return newUser;
};

export const createAppleUserProfile = async (userData) => {
  setUserTypeProperty('audience');
  const newUser = {
    id: userData.uid,
    email: userData.email,
    name: userData.displayName,
    isAnonymous: false,
    userType: 'audience',
    loginProvider: 'apple',
  };
  await createNewUserProfile(newUser);
  return newUser;
};

export const sendArtistRequest = async (reqData) => {
  return sendRequest(reqData);
};

export const signOut = async () => logOff();
