import React, { useState, useEffect, useParams } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styles from './confirmationPage.css'

export default function SubscriptionCanceledPage() {
  const history =  useHistory()
  const { pathname } = useLocation()

  function handleClick () {
    const newPath = pathname.replace('subscription', 'ecommerce/product')
    history.push(newPath)
  }

  return (
    <div className='subscription-Final-Step'>
      <h1>Purchase canceled</h1>
      <button className='confirmationButton' onClick={handleClick}> Try again </button>
    </div>
  );
};
