import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../../utils/slick.css';
import '../../../utils/slickTheme.css';
import '../LiveSession.css';
import {
  cityArtistSlideIndexUpdated,
  cityTop3DisplayStatusChanged,
  currenArtistOnSlideChange,
  sliderMovingChange
} from '../liveSessionSlice';
import { ContestantVideoPlaceholder } from './ContestantVideo';
import SessionArtistCard from './SessionArtistCard';
import './citySession.css';


const useStyles = makeStyles(() => ({
  mainSlider: {
    position: 'absolute',
    width: '100vw',
    top: 0,
    bottom: 0,
  },
  containerFullScreen: {
    height: '100vh',
  },
  containerFullScreenDial: {
    height: '100vh',
    position: 'absolute',
    top: 0,
    zIndex: 1,
    pointerEvents: 'none',
  },
}));

const styles = {
  width: '100vw',
  height: '100vh',
};

export const VideoScreen = ({ contestants, currentCitySessionIndex, citySessionId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainSwiper, setMainSwiper] = useState();
  const [artistSwiper, setArtistSwiper] = useState();

  let { cityId, artistId } = useParams();

  useEffect(() => {
    if (contestants.length === 0) {
      //dispatch(getCitySession({ cityId: citySessionId }));
      return;
    }
    if (cityId && !artistId && mainSwiper && artistSwiper) {
      mainSwiper.slideTo(0);
      artistSwiper.slideTo(1);
    }

    let findArtist = contestants.findIndex((contestant) => contestant.id === artistId);
    if (findArtist >= 0 && mainSwiper) {
      mainSwiper.slideTo(1);
      artistSwiper.slideTo(findArtist + 1);
    }
  }, [mainSwiper, cityId, artistId]);

  useEffect(() => {
    dispatch(currenArtistOnSlideChange(contestants[currentIndex]));
  }, [currentIndex, currentCitySessionIndex]);

  const handleSlideChange = (swiper) => {
    let currentIndex = 0;
    if (swiper.activeIndex !== 0) {
      currentIndex = swiper.activeIndex > contestants.length ? 0 : swiper.activeIndex - 1;
    } else {
      currentIndex = contestants.length - 1;
    }
    setCurrentIndex(currentIndex);
    dispatch(cityArtistSlideIndexUpdated({ cityId: citySessionId, index: currentIndex }));
  };

  const onMainSlideChange = (mainSwiper) => {
    dispatch(
      cityTop3DisplayStatusChanged({
        cityId: citySessionId,
        showing: mainSwiper.activeIndex === 0,
      }),
    );
  };

  const onMainSwiper = (mainSwiper) => {
    dispatch(
      cityTop3DisplayStatusChanged({
        cityId: citySessionId,
        showing: mainSwiper.activeIndex === 0,
      }),
    );
    setMainSwiper(mainSwiper);
  };

  const handleSlideMove = (swiper, event) => {
    dispatch(sliderMovingChange(true))
  }

  const handleTransitionResetEnd = (swiper, event) => {
    dispatch(sliderMovingChange(false))
  }

  const onViewCitySessionClick = (event) => {
    event.stopPropagation();
    mainSwiper.slideNext();
  };
  const slideName = `slide-${citySessionId}`;
  return (
    <div>
      <Swiper
        slidesPerView={1}
        centeredSlides={true}
        direction="vertical"
        initialSlide={2}
        spaceBetween={0}
        onSwiper={onMainSwiper}
        onSlideChange={onMainSlideChange}
      >
        <SwiperSlide key="top3">
          <div className="verticalSlider">
            <SessionArtistCard
              onViewCitySessionClick={onViewCitySessionClick}
              cityId={citySessionId}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide id={slideName} key="artistsSlider">
          <div>
            <Swiper
              style={styles}
              initialSlide={0}
              spaceBetween={0}
              slidesPerView={1}
              direction="vertical"
              centeredSlides={true}
              loop={true}
              onSwiper={setArtistSwiper}
              onSlideChange={handleSlideChange}
              onSliderMove={handleSlideMove}
              onSlideResetTransitionEnd={handleTransitionResetEnd}
              onSlideChangeTransitionEnd={handleTransitionResetEnd}
              mousewheel
            >
              {(contestants || []).map((contestant, contestantIndex) => (
                <SwiperSlide key={contestant.id} virtualIndex={contestantIndex}>
                 
                    <ContestantVideoPlaceholder contestant={contestant} />
                  
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
