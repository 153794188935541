import React from 'react';
import { useSelector } from 'react-redux';

import { selectCurrentUser } from '../auth/authSlice';
import { useLocation } from 'react-router-dom';
import { ProfileContainer } from './ProfileContainer';

export function LeaderProfile() {
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();
  const leaderArtistId = location.state && location.state.artistId;
  const position = location.state && location.state.position;

  return (
    <div>
      <ProfileContainer
        isCurrentUser={false}
        artistId={leaderArtistId || (currentUser && currentUser.id)}
        position={position}
      />
    </div>
  );
}
