import React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
// import { SignUpForm } from './signupForm/SignUpForm';
import styles from './signupForm/SignUpForm.module.css';

import { selectCurrentUser, selectCurrentUserLoading } from './authSlice';
import { FanProfile } from '../fanProfile/FanProfile';

import { ArtistProfile } from '../artistProfile/ArtistProfile';
import { SignUpForm } from './signupForm/SignUpForm';

export function Profile() {
  const currentUser = useSelector(selectCurrentUser);
  const isLoadingUser = useSelector(selectCurrentUserLoading);

  if (isLoadingUser) {
    return (
      <div style={{ height: '100%', display: 'grid', placeItems: 'center' }}>
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return currentUser ? (
    currentUser.userType === 'audience' ? (
      <FanProfile />
    ) : (
      <ArtistProfile />
    )
  ) : (
    <div
      className={styles.signInContainer}
      style={{ backgroundImage: "url('/assets/signupbg.png')" }}
    >
      <SignUpForm message={'You need to sign in order to vote!'} />
    </div>
  );
}
