import { createMuiTheme } from '@material-ui/core/styles';
const theme = {
  typography: {
    fontFamily: 'Gotham light',
  },
  palette: {
    primary: {
      main: '#f90103',
    },
    type: 'dark',
    secondary: {
      main: '#f90103',
    },
    background: {
      default: '#110215',
      paper: '#110215',
    },
  },
};

export default createMuiTheme(theme);
