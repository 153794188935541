import React from 'react';
import { useSelector } from 'react-redux';
import styles from './ArtistProfile.module.css';
import { selectCurrentUser } from '../auth/authSlice';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import { ProfileContainer } from './ProfileContainer';

export function ArtistProfile() {
  const currentUser = useSelector(selectCurrentUser);
  const history = useHistory();

  return currentUser && currentUser.userType === 'artist' ? (
    <div>
      <IconButton
        onClick={() => history.push('/profile/options')}
        className={styles.elipsisIcon}
        color="primary"
        aria-label="upload picture"
        component="span"
      >
        <MoreVertIcon />
      </IconButton>

      <ProfileContainer isCurrentUser={true} artistId={currentUser && currentUser.id} />
    </div>
  ) : (
    <div />
  );
}
